import React from 'react'

const AllNFT = () => {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M235.9 34C231.8 35 225.8 37 222.7 38.4C216.3 41.2 94.6 111.2 86 117C74.2 125 62.3 140 56.5 154.1C50.4 169.1 50.5 168 50.5 256C50.5 344 50.4 342.9 56.5 357.9C62.3 372 74.2 387 86 395C94.6 400.7 216.3 470.8 222.6 473.6C242 482.2 269.9 482.2 289.3 473.6C296.6 470.4 418.8 399.9 426.5 394.5C439.1 385.6 449.5 372.3 455.3 357.8C461.5 342.3 461.3 345.4 461.7 263C462 213.5 461.8 184.1 461.1 177.6C459.8 165.7 455.5 152.4 449.9 142.8C445 134.4 434.7 123.3 426.5 117.5C418.9 112.1 296.6 41.6 289.2 38.3C274.7 31.9 251.9 30.1 235.9 34ZM271.2 67.1C274.6 68.2 305.8 85.5 341.9 106.3C400.2 139.9 407.2 144.1 413.3 150.1C421.8 158.6 426.5 167.8 428.2 179.8C429.1 186.5 429.1 188.3 428.1 189C427.3 189.4 423.3 190.3 419.1 191C390.8 195.6 354.5 207.7 324.9 222.6C255.4 257.4 195.9 319.2 165.3 388.1C162.7 394 160.1 399 159.6 399C159.1 399 146.7 392.1 132.2 383.8C100.6 365.6 95.1 361.1 89 348.6C86.7 343.8 84.6 337.8 84 334C82.7 325.3 82.7 186.7 84 178C84.6 174.2 86.7 168.2 89 163.4C95.9 149.3 97.1 148.4 170.2 106.3C205.6 85.9 237.3 68.2 240.6 67.1C248.8 64.3 262.8 64.2 271.2 67.1ZM428.7 279.2C428.4 340.4 428.6 338 422.5 350.1C420.9 353.1 416.7 358.4 413.1 362C407.2 367.8 399.7 372.4 341.9 405.7C305.8 426.5 274.6 443.8 271.2 444.9C262.8 447.8 249.2 447.8 240.8 444.9C237.5 443.8 224.5 437 212 429.8C194.6 419.8 189.3 416.3 189.8 415.1C191.5 410 200.9 390.4 205.7 381.7C243.7 312.7 307.4 259.7 382.8 234.3C395.8 230 422.3 223.2 426.8 223.1L429.1 223L428.7 279.2Z" fill="white"/>
    <path d="M175.1 139.5C156.5 143.6 141.7 156.3 134.5 174.5C132.3 180 131.6 183.6 131.2 191.5C130.4 209.1 135.4 222.4 147.5 234.5C159.3 246.3 173.5 251.7 190.5 250.7C215 249.3 234.5 233.6 241.6 209.4C244.1 201.1 243.9 186.5 241.1 178C232.1 149.9 203.8 133.3 175.1 139.5ZM197.8 173.9C206 178.1 211.9 189.3 210.4 197.7C208.3 209.5 198.2 218 186.4 218C181 218 173 214 169.3 209.6C156.4 193.9 168.6 169.8 188.6 171.4C191.4 171.6 195.6 172.8 197.8 173.9Z" fill="white"/>
    </svg>
    
  )
}

export default AllNFT