/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import Home from "./pages/home";

import Marketplacecollection from "./pages/Marketplacecollection";
import Marketplace from "./pages/Marketplace";
import NFTDetails from "./pages/NFTDetails";

import Collection from "./pages/collection";
import CollectionWithCollection from "./pages/CollectionWithCollection";
import Author from "./pages/Author";
import CollectionActivity from "./pages/CollectionActivity";
import UserProfile from "./pages/UserProfile";
import Profile from "./components/Profile";

import MintCollection from "./pages/MintCollection";
import MintCollectionLive from "./pages/MintCollectionLive";
import "./components-css/App.css";
import Minttab from "./components/Minttab";
import UpdateProfile from "./pages/updateProfile";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import ItemNotFound from "./pages/ItemNotFound";
import MintingPage from "./pages/MintingPage";
import MultiMintingPage from "./pages/MultiMintingPage";
import Offers from "./pages/Offers";
import AccountSupport from "./pages/AccountSupport";
import Header from "./menu/header";
import MyNFTs from "./pages/MyNFTs";
import ModalPage from './pages/ModalPage';
import { useCookies } from "react-cookie";
import { NotificationContainer, NotificationManager } from "react-notifications";
import { SkeletonTheme } from "react-loading-skeleton";
import DisplayPopupModal from "./components/AccountModal/DisplayPopup";
import { checkAuthorization } from "../apiServices";
import { NOTIFICATION_DELAY } from "../helpers/constants";

import evt from "../events/events";
import Cmmg from "./pages/Minting/Cmmg";
import Zev from "./pages/Minting/Zev";
import Barrett from "./pages/Minting/Barrett";
import Metamarine from "./pages/Minting/Metamarine";
import AboutMetaMarine from "./pages/AboutMetamarine"
import contracts from "../config/contracts";
import { ClientContextProvider, useWalletConnectClient } from "../contexts/ClientContext";


const App = () => {
  const [currentUser, setCurrentUser] = useState();
  const [cookies] = useCookies([]);

  console.log("contractss", contracts)


  useEffect(() => {
    if (cookies["selected_account"])
      setCurrentUser(cookies["selected_account"]);

  }, [cookies["selected_account"]]);



  useEffect(() => {
    console.log("localStorage.getItem()", localStorage.getItem("Web3Auth-cachedAdapter"))
    const fetch = async () => {
      let res = await checkAuthorization()
      console.log("res", res)
      if (res !== true) {
        evt.emit("disconnectWallet")
        NotificationManager.error("Unauthorized User", "", NOTIFICATION_DELAY)
        setCurrentUser("");
      }
    }
    if (currentUser) {
      fetch()
      setTimeout(function () {
        // Code to be executed after 5 seconds wallet-connect-v1
        console.log('Five seconds have passed!');
        let connectedWith = "";
        let supportedAdapter = ["metamask", "wallet-connect-v2", "openlogin", "torus-evm"];
        let cachedAdapter = localStorage.getItem("Web3Auth-cachedAdapter");
        let providerConnected = localStorage.getItem("DA-connected-provider")
        if (providerConnected !== "web3Auth" || providerConnected !== "metamask") {
          return
        }
        if (supportedAdapter.indexOf(cachedAdapter) >= 0 && cachedAdapter !== null) {
          console.log("all good")
          connectedWith = cachedAdapter;
          let openlogin_store = localStorage.getItem("openlogin_store");
          if (cachedAdapter === "wallet-connect-v1") {
            openlogin_store = localStorage.getItem("walletconnect");
          }
          openlogin_store = JSON.parse(openlogin_store)
          console.log("openlogin_store ", openlogin_store, openlogin_store?.connected)
          if (openlogin_store?.peerMeta?.name) {
            connectedWith = openlogin_store?.peerMeta?.name;
          }
          if (openlogin_store?.typeOfLogin) {
            connectedWith = openlogin_store?.typeOfLogin;
          }
          localStorage.setItem("connectedPlatform", connectedWith)
        } else {
          evt.emit("disconnectWallet")
          NotificationManager.error(" Wallet Logged out", "", NOTIFICATION_DELAY)
        }
      }, 5000); // 5000 milliseconds = 5 seconds
    }
  }, [currentUser, localStorage.getItem("Web3Auth-cachedAdapter")])

  const {
    client,
    session,
    disconnect,
    chain,
    accounts,
    balances,
    chainData,
    isFetchingBalances,
    isInitializing,
    connect,
    web3Provider,
  } = useWalletConnectClient();

  console.log("in nftdetail provider", accounts)
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <ClientContextProvider>
        <Router>
          <Header />
          <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/author/:id" element={<Author />} />
            {cookies["selected_account"] ? <Route exact path="/myNfts" element={<MyNFTs />} /> : <Route exact path="/" element={<Home />} />}
            {cookies["selected_account"] ? <Route path="/updateProfile" element={<UpdateProfile />} /> : <Route exact path="/" element={<Home />} />}
            {cookies["selected_account"] ? <Route path="/userprofile" element={<UserProfile />} /> : <Route exact path="/" element={<Home />} />}
            {cookies["selected_account"] ? <Route path="/profile" element={<Profile />} /> : <Route exact path="/" element={<Home />} />}

            <Route
              path="/marketplaceCollection"
              element={<Marketplacecollection />}
            />

            <Route
              path="/marketplaceCollection/:searchedText"
              element={<Marketplacecollection />}
            />
            <Route path="/marketplace" element={<Marketplace />} />
            <Route path="/marketplace/:searchedText" element={<Marketplace />} />
            <Route path="/NFTdetails/:id" element={<NFTDetails />} />

            <Route path="/collection/:id" element={<Collection />} />

            <Route
              path="/collectionwithcollection"
              element={<CollectionWithCollection />}
            />
            <Route
              path="/collectionwithcollection/:brandID"
              element={<CollectionWithCollection />}
            />
            <Route path="/collectionActivity" element={<CollectionActivity />} />

            <Route path="/modalpage" element={<ModalPage />} />

            <Route path="/mintcollection" element={<MintCollection />} />
            <Route path="/mintcollectionlive" element={<MintCollectionLive />} />
            <Route path="/minttab" element={<Minttab />} />
            <Route path="/mintingpage" element={<MintingPage />} />
            {/* <Route path="/multimintingpage/:id" element={<MultiMintingPage />} /> */}

            {/* Temporary routes */}
            {/* <Route path="/multimintingpage/cmmg" element={<Cmmg />} /> */}
            {/* <Route path="/multimintingpage/zev" element={<Zev />} /> */}
            {/* <Route path="/multimintingpage/barrett" element={<Barrett />} /> */}  
           <Route path="/multimintingpage/music" exact element={<Metamarine />} /> 
            {/* <Route path="/metamarine" element={<AboutMetaMarine />} /> */}


            <Route path="/offers" element={<Offers />} />
            <Route path="/accountsupport" element={<AccountSupport />} />
            <Route
              path="/viewImage"
              element={<DisplayPopupModal />}
            />
            <Route path="*" exact element={<ItemNotFound />} />
          </Routes>
          <NotificationContainer />
        </Router>
      </ClientContextProvider>
    </SkeletonTheme>
  );
};

export default App;