import React from 'react'

function ItemSVG() {
  return (
    //   <svg width="20" height="20" viewBox="0 0 25 25" fill="red" xmlns="http://www.w3.org/2000/svg">
    //     <path d="M0 2.34375C0 1.72215 0.24693 1.12601 0.686468 0.686468C1.12601 0.24693 1.72215 0 2.34375 0L22.6562 0C23.2779 0 23.874 0.24693 24.3135 0.686468C24.7531 1.12601 25 1.72215 25 2.34375V22.6562C25 23.2779 24.7531 23.874 24.3135 24.3135C23.874 24.7531 23.2779 25 22.6562 25H2.34375C1.72215 25 1.12601 24.7531 0.686468 24.3135C0.24693 23.874 0 23.2779 0 22.6562L0 2.34375ZM2.34375 1.5625C2.13655 1.5625 1.93784 1.64481 1.79132 1.79132C1.64481 1.93784 1.5625 2.13655 1.5625 2.34375V7.8125H7.8125V1.5625H2.34375ZM7.8125 9.375H1.5625V15.625H7.8125V9.375ZM9.375 15.625H15.625V9.375H9.375V15.625ZM7.8125 17.1875H1.5625V22.6562C1.5625 22.8635 1.64481 23.0622 1.79132 23.2087C1.93784 23.3552 2.13655 23.4375 2.34375 23.4375H7.8125V17.1875ZM9.375 17.1875V23.4375H15.625V17.1875H9.375ZM17.1875 17.1875V23.4375H22.6562C22.8635 23.4375 23.0622 23.3552 23.2087 23.2087C23.3552 23.0622 23.4375 22.8635 23.4375 22.6562V17.1875H17.1875ZM17.1875 15.625H23.4375V9.375H17.1875V15.625ZM17.1875 7.8125H23.4375V2.34375C23.4375 2.13655 23.3552 1.93784 23.2087 1.79132C23.0622 1.64481 22.8635 1.5625 22.6562 1.5625H17.1875V7.8125ZM15.625 7.8125V1.5625H9.375V7.8125H15.625Z" fill="url(#paint0_linear_20_863)"/>
    // </svg>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 1.875C0 1.37772 0.197544 0.900808 0.549174 0.549174C0.900808 0.197544 1.37772 0 1.875 0H18.125C18.6223 0 19.0992 0.197544 19.4508 0.549174C19.8025 0.900808 20 1.37772 20 1.875V18.125C20 18.6223 19.8025 19.0992 19.4508 19.4508C19.0992 19.8025 18.6223 20 18.125 20H1.875C1.37772 20 0.900808 19.8025 0.549174 19.4508C0.197544 19.0992 0 18.6223 0 18.125V1.875ZM1.875 1.25C1.70924 1.25 1.55027 1.31585 1.43306 1.43306C1.31585 1.55027 1.25 1.70924 1.25 1.875V6.25H6.25V1.25H1.875ZM6.25 7.5H1.25V12.5H6.25V7.5ZM7.5 12.5H12.5V7.5H7.5V12.5ZM6.25 13.75H1.25V18.125C1.25 18.2908 1.31585 18.4498 1.43306 18.567C1.55027 18.6842 1.70924 18.75 1.875 18.75H6.25V13.75ZM7.5 13.75V18.75H12.5V13.75H7.5ZM13.75 13.75V18.75H18.125C18.2908 18.75 18.4498 18.6842 18.567 18.567C18.6842 18.4498 18.75 18.2908 18.75 18.125V13.75H13.75ZM13.75 12.5H18.75V7.5H13.75V12.5ZM13.75 6.25H18.75V1.875C18.75 1.70924 18.6842 1.55027 18.567 1.43306C18.4498 1.31585 18.2908 1.25 18.125 1.25H13.75V6.25ZM12.5 6.25V1.25H7.5V6.25H12.5Z" fill="url(#paint0_linear_101_2)" />
      <defs>
        <linearGradient id="paint0_linear_101_2" x1="10" y1="0" x2="10" y2="20" gradientUnits="userSpaceOnUse">
          <stop stop-color="#EAB978" />
          <stop offset="1" stop-color="#846844" />
        </linearGradient>
      </defs>
    </svg>

  )
}

export default ItemSVG
