import React, { useEffect, useState } from 'react';
import { emailOctopus } from "email-octopus-ts";
import { NOTIFICATION_DELAY } from '../../helpers/constants';
import { NotificationManager } from "react-notifications";
import { emailOctopusSubscribe, emailOctopusSubscribe1 } from '../../apiServices';

const EmailForm = function () {
	const [email, setEmail] = useState("");

	const isValidEmail = async (email) => {
        var atposition = email.indexOf("@");
        var dotposition = email.lastIndexOf(".");
        if ( atposition < 1 || dotposition < atposition + 2 || dotposition + 2 >= email.length ) {
            return false;
        }
        return true;
    };
	const subscribeUser = async (event) => {
		event.preventDefault();
        if (email === "" || email === undefined) {
            NotificationManager.error("Please input Email Address", "", NOTIFICATION_DELAY);
            return;
        }
        let validEmail = await isValidEmail(email);
        if (!validEmail) {
            NotificationManager.error("Please Enter a Valid E-mail Address", "", NOTIFICATION_DELAY);
            return;
        }
        try {
            const postReq = {
                email_address: email
            };
            let subscribe = await emailOctopusSubscribe(postReq);
            if(subscribe?.error){
                NotificationManager.error(subscribe?.error?.message ? subscribe.error.message : "Something Went Wrong", "", NOTIFICATION_DELAY);
            }else{
                NotificationManager.success("Thanks for Subscribing", "", NOTIFICATION_DELAY);
            }
            setEmail("");
        } catch (error) {
            console.log("error", error);
            setEmail("");
            NotificationManager.error("Something Went Wrong", "", NOTIFICATION_DELAY);
        }
    }

    

  return (<div id="EmailoctopusForm">
	<div className="form-container">
		<div className="emailoctopus-form-wrapper emailoctopus-form-default" >
			<p className="emailoctopus-success-message"></p>
			<p className="emailoctopus-error-message"></p>
			<div className="emailoctopus-form" >
				<div className="main-form">
                    <div eo-form-fields-container="">
                        <div className="emailoctopus-form-row form-group mb-2" >
                            <input 
								type="email" 
								placeholder="Enter your email address" 
								required="" 
								value={email}
								onChange={(e) => {
									setEmail(e.target.value)  
								}} 
								className="form-control form-control-placeholder controllers"
							/>
                        </div>
                    </div>
                    <input type="submit" onClick={subscribeUser} className="btn w-100 btn-primary mb-2" value="Subscribe"/>
                </div>
			</div>
        </div>
    </div>


  </div>);
}
export default EmailForm;