import React from 'react'

function ActivitySVG() {
  return (
    <svg width="25" height="20" viewBox="0 0 25 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M24.75 16.8351H0.25C0.1125 16.8351 0 16.9476 0 17.0851V18.8351C0 18.9726 0.1125 19.0851 0.25 19.0851H24.75C24.8875 19.0851 25 18.9726 25 18.8351V17.0851C25 16.9476 24.8875 16.8351 24.75 16.8351ZM1.67812 12.9226L2.91875 14.157C3.01562 14.2539 3.175 14.2539 3.27187 14.157L10.6062 6.84762L13.6562 9.88825C13.8442 10.0744 14.098 10.1789 14.3625 10.1789C14.627 10.1789 14.8808 10.0744 15.0688 9.88825L23.325 1.66325C23.4219 1.56638 23.4219 1.407 23.325 1.31012L22.0844 0.0726252C22.0374 0.0260995 21.9739 0 21.9078 0C21.8417 0 21.7782 0.0260995 21.7313 0.0726252L14.3656 7.41638L11.3125 4.37575C11.1246 4.18957 10.8708 4.08512 10.6062 4.08512C10.3417 4.08512 10.0879 4.18957 9.9 4.37575L1.67812 12.5695C1.65475 12.5926 1.63619 12.6201 1.62352 12.6504C1.61085 12.6807 1.60433 12.7132 1.60433 12.7461C1.60433 12.7789 1.61085 12.8114 1.62352 12.8417C1.63619 12.8721 1.65475 12.8995 1.67812 12.9226Z" fill="#EF981D"/>
    </svg>
  )
}

export default ActivitySVG
