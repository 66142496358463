import { React, useEffect, useState } from "react";
import Footer from "../../components/footer";
import DevTeam from "./../../../assets/images/devTeam.png";
import "../../components-css/App.css";
import coverImg from "../../../assets/Minting/cmmg.jpg";
import { exportInstance, FetchInstance } from "../../../apiServices";
import {
  getCurrentProvider,
  getUsersTokenBalance,
} from "../../../helpers/getterFunctions";
import metamarineAbi from "./../../../config/abis/metamarine.json";
import erc20Abi from "./../../../config/abis/erc20.json";
import { useCookies } from "react-cookie";
import BigNumber from "bignumber.js";
import { NOTIFICATION_DELAY } from "../../../helpers/constants";
import { WalletConditions } from "../../components/WalletConditions";
import eventEmitter from "../../../events/events";
import Spinner from "../../components/Spinner";
import PopupModal from "../../components/AccountModal/popupModal";
import PopupModalStripe from "../../components/AccountModal/popupModalStripe";
import { convertToEth } from "../../../helpers/numberFormatter";
import NotificationManager from "react-notifications/lib/NotificationManager";
import {
  getAllCountries,
  getAllStates,
  getUserQuantity,
} from "../../../apiServices";
import bodyBGImg from "./../../../assets/images/background.jpg";
// import NFTPlaceHolder from "./../../../assets/metamarine/metaMarineNFT.png";
import NFTPlaceHolder from "./../../../../src/assets/mint_event.png";
import ProgressModal from "../../components/AccountModal/ProgressModal";
import { Link } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import contracts from "../../../config/contracts";
import { web3authInstance } from "../../menu/header";
import { ethers } from "ethers";
// import Logo from "./../../../assets/images/logo.svg";
import BGImg from "../../../../src/assets/Section.png";
import linkLogoImg from "../../../../src/assets/Link.png";
import "./minting.css";
import { useWalletConnectClient } from "../../../contexts/ClientContext";
import { switchNetwork } from "../../../helpers/sendFunctions";
import { CopyToClipboard } from "react-copy-to-clipboard";

const contractAddress = contracts.MUSIC;
const catId = process.env.REACT_APP_MUSIC_CATID;
const NFT_PRICE_IN_DOLLAR = process.env.REACT_APP_MUSIC_PRICE_IN_DOLLAR;
const CURRENCY = "USD";
const SYMBOL = "$";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_API_KEY);

function Metamarine() {
  let Logo = process.env.REACT_APP_ASSETS_BASE_PATH + "assets/images/Link.png";
  const [toggle, setToggle] = useState(false);
  const [contract, setContract] = useState();
  const [cookies] = useCookies([]);
  const [currQty, setCurrQty] = useState(1);
  const [catInfo, setCatInfo] = useState();
  const [currentUser, setCurrentUser] = useState();
  const [loading, setLoading] = useState(false);
  const [isShowModal, setIsShowModal] = useState(false);
  const [reload, setReload] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [price, setPrice] = useState(0);
  const [popupdataData] = useState([]);
  const [userData, setUserData] = useState({
    fullName: "",
    emailAddress: "",
    country: "",
    state: "",
    postalCode: "",
  });
  const [countryList, setCountryList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [closeDisabled] = useState(true);
  const [totalMintedByUser, setTotalMintedByUser] = useState(0);
  const [totalItems, setTotalItems] = useState(0);
  const [maxperWallet, setMaxperWallet] = useState(0);
  const [maxNFTs, setMaxNFTs] = useState(0);
  const [userQtyDB, setUserQtyDB] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [orderCompleted, setOrderCompleted] = useState(0);
  const [showAlert, setShowAlert] = useState("");

  const { web3Provider } = useWalletConnectClient();
  const [provider, setProvider] = useState();
  const [isCopied, setIsCopied] = useState(false);

  useEffect(() => {
    const fetch = async () => {
      let res = await getCurrentProvider(web3Provider);
      setProvider(res);
    };
    fetch();
  }, [web3Provider]);

  const handleShowAlert = (error) => {
    setShowAlert(error);
  };

  var register_bg = {
    backgroundImage: `url("https://digital-arms-meta-dev.s3.us-east-2.amazonaws.com/collectionImages/1721996654665-Music4x.png")`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "370px",
  };

  const toggleLoader = (isLoading) => {
    if (isLoading === "completed") {
      setCurrQty(1);
      setOrderCompleted(1);
      setLoading(false);
    }
    if (isLoading === "end") {
      setLoading(false);
      console.log("Loader stopped");
    }
  };

  eventEmitter.on("processStart", toggleLoader);
  let paymentData = {};

  useEffect(() => {
    setCurrentUser(cookies.selected_account);
    const fetch = async () => {
      if (cookies.selected_account) {
        try {
          const countries = await getAllCountries();
          setCountryList(countries);
        } catch (err) {
          console.log("Error in getting userinfo", err);
        }
      }
    };
    fetch();
  }, [cookies.selected_account]);

  useEffect(() => {
    const fetch = async () => {
      console.log("currUser", currentUser);
      await getContractDetails();
      let qtyReq = {
        walletAddress: currentUser,
        contractAddress: contractAddress,
      };
      if (currentUser) {
        const userQty = await getUserQuantity(qtyReq);
        console.log("userinfo userQty", userQty);

        setUserQtyDB(userQty);
      }
    };
    fetch();
  }, [reload, currentUser]);

  useEffect(() => {
    const updateQty = async () => {
      if (parseInt(maxperWallet) <= parseInt(totalMintedByUser)) {
        setMaxNFTs(0);
        setCurrQty(0);
        return;
      }
      let qtyAllowed =
        parseInt(maxperWallet) -
        (parseInt(userQtyDB) + parseInt(totalMintedByUser));
      console.log(
        "qtyAllowed before",
        parseInt(maxperWallet),
        parseInt(userQtyDB),
        parseInt(totalMintedByUser),
        qtyAllowed
      );
      let leftItems = parseInt(totalItems) - parseInt(totalSupply);
      if (parseInt(qtyAllowed) > parseInt(leftItems)) {
        qtyAllowed = leftItems;
      }
      setMaxNFTs(qtyAllowed);

      console.log("qtyAllowed after", qtyAllowed);
    };
    updateQty();
  }, [userQtyDB, totalMintedByUser, maxperWallet]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateState = async (val) => {
    try {
      console.log("val", val);
      let reqData = { country: val };
      setLoading(true);
      let stateDB = await getAllStates(reqData);
      if (stateDB) {
        setStateList(stateDB);
      } else {
        setStateList([]);
      }
      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };

  const getContractDetails = async () => {
    try {
      setLoading(true);
      console.log("contractAddress", contractAddress);
      let contractInstance = await FetchInstance(
        contractAddress,
        metamarineAbi
      );
      console.log("contract instance 223", contractInstance);
      let categoryInfo;
      try {
        categoryInfo = await contractInstance.categories(catId);
      } catch (errcategoryInfo) {
        console.log("errCat", errcategoryInfo);
        setPrice("TBA");
        setTotalItems(0);
      }

      let totalMinted;
      try {
        totalMinted = await contractInstance.totalTokensMintedPerCategory(
          catId
        );
      } catch (errtotalMinted) {
        console.log("totalMinted", errtotalMinted);
      }

      let mintedByUser;
      try {
        if (currentUser) {
          console.log("currentUser ", currentUser);
          mintedByUser = await contractInstance.balanceOf(currentUser);
        }
      } catch (errmintedByUser) {
        console.log("errmintedByUser ", errmintedByUser);
        setPrice("TBA");
        setTotalItems(0);
      }
      try {
        let _totalSupply = await contractInstance.totalSupply();
        setTotalSupply(_totalSupply?.toString());
      } catch (errmintedByUser) {
        setTotalSupply(0);
        setPrice("TBA");
        setTotalItems(0);
        console.log("errmintedByUser ", errmintedByUser);
      }

      if (mintedByUser) {
        mintedByUser = mintedByUser?.toString();
      } else {
        mintedByUser = 0;
      }

      console.log("categoryInfo", categoryInfo);
      console.log("totalMinted", totalMinted?.toString());
      console.log("mintedByUser", mintedByUser?.toString());

      console.log("hereree", contractInstance);
      setPrice(convertToEth(categoryInfo?.price?.toString()).toString());
      setContract(contractInstance);
      setTotalMintedByUser(mintedByUser?.toString());
      const paredTotalItems = ethers.utils.formatEther(categoryInfo?.categoryTokenCap?.toString())
      setTotalItems(parseFloat(paredTotalItems));
      setMaxperWallet(categoryInfo?.maxPerAddress?.toString());
      setCatInfo(categoryInfo);

      setLoading(false);
    } catch (err) {
      console.log("err", err);
      setPrice("TBA");
      setTotalItems(0);
      setTotalMintedByUser(0);

      setLoading(false);
    }
  };
  const whitelistLogic = async () => {
    try {
      if (catInfo.isPrivate) {
        let isWhitelisted = await contract.allowMint(currentUser, catId);
        console.log("is whitelisted", isWhitelisted);
        if (isWhitelisted) return true;
        else return false;
      } else {
        return true;
      }
    } catch (err) {
      console.log("err", err);
      return false;
    }
  };

  const approvalLogic = async () => {
    try {
      console.log("payment token");
      let paymentToken = await catInfo?.paymentToken;

      console.log("payment token", paymentToken);
      let tokenContract = await exportInstance(
        paymentToken,
        erc20Abi,
        provider
      );
      let allowance = await tokenContract.allowance(
        currentUser,
        contractAddress
      );

      console.log(
        "cat info",
        catInfo,
        new BigNumber(catInfo?.price?.toString())
          .multipliedBy(currQty)
          .toFixed(),
        allowance.toString()
      );
      if (1) {
        let es = await tokenContract.estimateGas.approve(
          contractAddress,
          new BigNumber(catInfo?.price?.toString())
            .multipliedBy(currQty)
            .toFixed()
        );
        let priceLimit = new BigNumber(es.toString()).plus(
          new BigNumber(es.toString()).multipliedBy(0.1)
        );
        let options = {
          from: currentUser,
          gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
          value: 0,
        };

        let res = await tokenContract.approve(
          contractAddress,
          new BigNumber(catInfo?.price?.toString())
            .multipliedBy(currQty)
            .toFixed(),
          options
        );
        res = await res.wait();
        return true;
      }
      return true;
    } catch (err) {
      console.log("err", err);
      // User denied transaction

      if (err.message?.includes("user rejected transaction")) {
        NotificationManager.error("You have denied the transaction");
      }
      return false;
    }
  };

  const balanceChecks = async () => {
    if (currentUser) {
      let hntrbal = await getUsersTokenBalance(currentUser, contracts.USDT);
      console.log("balance", hntrbal);
      const web3authProvider = provider;
      // const web3authProvider = localStorage.getItem("web3AuthProvider")
      console.log("init modal is called---->", web3authProvider);

      let provider2 = new ethers.providers.Web3Provider(web3authProvider);
      let bnbBal = await provider2.getBalance(currentUser);
console.log(Number(convertToEth((hntrbal)?.toString())) , Number(price)*Number(currQty))
      if (Number(convertToEth((hntrbal)?.toString())) < Number(price)*Number(currQty)) {
        NotificationManager.error("Insufficient USDT balance");
        return false;
      }
      if (Number(convertToEth(bnbBal?.toString())) < 0.001) {
        NotificationManager.error("Insufficient MATIC balance");
        return false;
      }
      console.log("balance in bnb", bnbBal.toString());
      return true;
    }
  };

  const mintTokens = async () => {
    try {
      setLoading(true);
      let balCheck = await balanceChecks();
      if (!balCheck) {
        setLoading(false);
        return false;
      }
      let res = await approvalLogic();
      if (res === false) {
        setLoading(false);
        return;
      }

      let isWhitelisted = await whitelistLogic();
      if (!isWhitelisted) {
        NotificationManager.error(
          "User is not whitelisted",
          "",
          NOTIFICATION_DELAY
        );
        setLoading(false);
        return;
      }
      let _contract = await exportInstance(
        contractAddress,
        metamarineAbi,
        provider
      );
      console.log("_contract", _contract);
      let es = await _contract.estimateGas.mintTokens(catId, currQty);
      console.log("estimated gas", es);
      let priceLimit = new BigNumber(es.toString()).plus(
        new BigNumber(es.toString()).multipliedBy(0.5)
      );
      let options = {
        from: currentUser,
        gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
        value: 0,
      };
      let res1;
      try {
        res1 = await _contract.mintTokens(catId, currQty, options);
        console.log("response", res1);
      } catch (err) {
        console.log("err", err);
        if (err.message?.includes("user rejected transaction")) {
          NotificationManager.error("You have denied the transaction");
        }
      }
      res1 = await res1.wait();
      setOrderCompleted(1);
      setIsShowModal(1);
      NotificationManager.success("NFT Purchased Successfully");
      setLoading(false);
      // setCurrQty(1);
      // setReload(!reload);
    } catch (err) {
      console.log("err", err);
      setLoading(false);
    }
  };

  return (
    // <div style={bgImgStyle}>
    <div>
      {isShowPopup ? (
        <ProgressModal
          datas={popupdataData}
          onRequestClose={() => {
            setIsShowPopup(!isShowPopup);
            setReload(!reload);
            setCurrQty(1);
          }}
          disabled={closeDisabled}
        />
      ) : (
        ""
      )}
      {loading ? <Spinner /> : ""}
      {showAlert === "chainId" ? (
        <PopupModal
          content={
            <div className="popup-content1">
              <div class="model_data">
                <div className="bid_user_details mb-4">
                  <img src={Logo} alt="" />
                  <div className="bid_user_address">
                    <div>
                      <div className="mr-3 text-white">
                        Required Network ID:
                      </div>
                      <span className="adr">
                        {process.env.REACT_APP_NETWORK_ID}
                      </span>
                    </div>
                    <div>
                      <div className="mr-3 text-white">
                        Required Network Name:
                      </div>
                      <span className="adr">
                        {process.env.REACT_APP_NETWORK}
                      </span>
                    </div>
                  </div>
                </div>
                <button
                  className="btn-main mt-2 mb-1"
                  onClick={async () => {
                    await switchNetwork();
                    setShowAlert("");
                  }}
                >
                  {"Switch Network"}
                </button>
              </div>
            </div>
          }
          handleClose={() => {
            setShowAlert(!showAlert);
          }}
        />
      ) : showAlert === "account" ? (
        <PopupModal
          content={
            <div class="model_data">
              <div className="popup-content1">
                <div className="bid_user_details mb-4">
                  <img src={Logo} alt="" />
                  <div className="bid_user_address align-items-center">
                    <div>
                      <span className="adr text-muted">{currentUser}</span>
                      <span className="badge badge-success">Connected</span>
                    </div>
                    <h4 className="mb-3">
                      Please switch to connected wallet address or click logout
                      to continue with the current wallet address by
                      disconnecting the already connected account.
                    </h4>
                  </div>

                  <button
                    className="btn-main mt-2 mb-1"
                    onClick={() => {
                      eventEmitter.emit("disconnectWallet");
                    }}
                  >
                    {"Logout"}
                  </button>
                </div>
              </div>
            </div>
          }
          handleClose={() => {
            setShowAlert(!showAlert);
          }}
        />
      ) : showAlert === "locked" ? (
        <PopupModal
          content={
            <div className="popup-content1">
              <div class="model_data">
                <div className="bid_user_details mb-4">
                  <img src={Logo} alt="" />
                  <div className="bid_user_address align-items-center">
                    <div>
                      <span className="adr text-muted">{currentUser}</span>
                      <span className="badge badge-success">Connected</span>
                    </div>
                  </div>
                  <h4 className="mb-3">
                    Your wallet is locked. Please unlock your wallet and connect
                    again.
                  </h4>
                </div>
                <button
                  className="btn-main mt-2 mb-1"
                  onClick={() => {
                    eventEmitter.emit("disconnectWallet");
                  }}
                >
                  Connect Wallet
                </button>
              </div>
            </div>
          }
          handleClose={() => {
            setShowAlert(!showAlert);
          }}
        />
      ) : showAlert === "notConnected" ? (
        <PopupModal
          content={
            <div className="popup-content1">
              <div class="model_data">
                <div className="bid_user_details my-4">
                  <img src={Logo} alt="" />
                  <h4 className="mb-3">Please connect your wallet. </h4>
                </div>
                <button
                  className="btn-main mt-2 mb-1"
                  onClick={() => {
                    setShowAlert("");
                    eventEmitter.emit("connectWallet");
                  }}
                >
                  Connect Wallet
                </button>
              </div>
            </div>
          }
          handleClose={() => {
            setShowAlert(!showAlert);
          }}
        />
      ) : (
        ""
      )}
      {/* <section
        className="collection_banner pdd_8"
        style={{
          backgroundImage: `url(${BGImg})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPositionX: "center",
          backgroundPositionY: "center",
          backgroundColor: "#000",
        }}
      ></section> */}

      <section className="register_hd bgOverLay pdd_12" style={register_bg}>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1>Byte Bridge Music</h1>
            </div>
          </div>
        </div>
      </section>

      <section
        className="collection_info"
        style={{ backgroundColor: "#1B1B1B" }}
      >
        <div className="container">
          <div className="collection_pick2">
            <img
              alt=""
              src={"/img/Group6.png"}
              className="img-fluid "
              style={{ "max-width": "35%" }}
              onError={(e) => (e.target.src = "../img/collections/list4.png")}
            />
          </div>
          <div className="coppycode text-center my-5">
              <span className="ctc">
                <div className="logo-address-container">

                <img alt="" src={"../img/small-logo.png"} className="img-fluid" />
                </div>
                <div className="d-flex align-items-end justify-content-center" style={{"marginLeft":"10px"}}>
                  {contractAddress
                    ? contractAddress?.slice(0, 4) +
                    "..." +
                    contractAddress?.slice(38, 42)
                    : "-"}
                <CopyToClipboard
                  text={contractAddress}
                  onCopy={() => {
                    setIsCopied(true);
                    setTimeout(() => {
                      setIsCopied(false);
                    }, 1000);
                  }}
                >
                  <svg
                    width="21"
                    height="24"
                    viewBox="0 0 21 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M15 21V22.875C15 23.4963 14.4963 24 13.875 24H1.125C0.503672 24 0 23.4963 0 22.875V5.625C0 5.00367 0.503672 4.5 1.125 4.5H4.5V18.375C4.5 19.8225 5.67755 21 7.125 21H15ZM15 4.875V0H7.125C6.50367 0 6 0.503672 6 1.125V18.375C6 18.9963 6.50367 19.5 7.125 19.5H19.875C20.4963 19.5 21 18.9963 21 18.375V6H16.125C15.5063 6 15 5.49375 15 4.875ZM20.6705 3.42052L17.5795 0.329484C17.3685 0.11852 17.0824 1.55998e-06 16.784 0L16.5 0V4.5H21V4.21598C21 3.91763 20.8815 3.63149 20.6705 3.42052Z"
                      fill="#fff"
                    />
                  </svg>
                </CopyToClipboard>
                </div>

                {isCopied ? <p className="copied">Copied!</p> : ""}
              </span>
            </div>

          <ul className="collection_status mt-5 mb-5">
            <li>
              {console.log(
                "totalSupply",
                totalSupply,
                totalItems,
                price == "NaN",
                isNaN(price)
              )}
              <h4>
                {totalSupply}/{totalItems ? totalItems?.toString() : 0}
              </h4>
              {/* <h4>616</h4> */}
              <p>Items Minted</p>
            </li>
            <li>
              <h4>{price && price != "NaN" ? price : "TBA"}</h4>
              {/* <h4>TBA</h4> */}
              <p>USDT</p>
            </li>
            <li>
              <h4>{NFT_PRICE_IN_DOLLAR}</h4>

              <p>USD</p>
            </li>

            <li>
              <h4>{catInfo?.isActive ? "Active" : "Inactive"}</h4>
              {/* <h4>Inactive</h4> */}
              <p>Status</p>
            </li>
          </ul>
          <div className="collection_description text-center">
            {!toggle ? (
              <p>
                Welcome to our Music Instruments and Notes NFT Collection, where
                the beauty of music is brought to life through stunning digital
                art. This collection celebrates the essence...
              </p>
            ) : (
              <>
                <p>
                  Welcome to our Music Instruments and Notes NFT Collection,
                  where the beauty of music is brought to life through stunning
                  digital art. This collection celebrates the essence of music
                  by featuring NFTs that showcase a variety of musical
                  instruments and notes, capturing the soul and spirit of sound.
                </p>
              </>
            )}

            {
              <span className="top_arrow">
                <img
                  alt=""
                  src={
                    !toggle ? "../img/bottom_arrow.png" : "../img/top_arrow.png"
                  }
                  onClick={() => setToggle((toggle) => !toggle)}
                  className="img-showMore less img-fluid"
                />
              </span>
            }
          </div>
          {/* <div className="collection_description text-center">
            <Link to={"/metamarine"} target="blank" className="view_all_bdr">
              About Meta Marine
            </Link>
          </div> */}
        </div>
      </section>
      <section className="collection_list">
        <div className="container">
          <div className="event_slider">
            <div className="mintevent text-center">
              {/* <div className="start_btn stamintFunctionbtn">{catInfo?.isActive ? "Active" : "TBA"}</div> */}
              <div className="start_btn stamintFunctionbtn">
                {catInfo?.isActive ? "Active" : "Inactive"}
              </div>
              <h4>Mint Event</h4>
              <div className="da_img mb-3">
                <img src={NFTPlaceHolder} alt="" />
              </div>
              {!currentUser && (
                <button
                  className="connect_wallet_btn mb-4 disabled"
                  onClick={() => {
                    eventEmitter.emit("connectWallet");
                  }}
                >
                  Connect Wallet
                </button>
              )}
              <div className="amount">
                <div className="amount_pass">
                  <div className="selective_amount">
                    <h5>Select Quantity</h5>
                    <p>Minimum Quantity for mint is 1</p>
                  </div>
                  <div className="qt_selector">
                    <button
                      onClick={() => {
                        if (!currentUser) {
                          NotificationManager.error(
                            "Please Connect Wallet First"
                          );
                          return;
                        }
                        console.log(
                          currQty,
                          Number(totalMintedByUser),
                          maxNFTs
                        );
                        let mint = currQty - 1;
                        if (mint < 1) mint = 1;
                        if (mint > maxNFTs) mint = maxNFTs;

                        setCurrQty(Number(mint));
                      }}
                    >
                      -
                    </button>

                    <input
                      type="text"
                      name=""
                      required=""
                      id=""
                      onKeyPress={(e) => {
                        if (!/^\d+$/.test(e.key)) e.preventDefault();
                      }}
                      onChange={(e) => {
                        e.preventDefault();

                        if (
                          Number(e.target.value) + Number(totalMintedByUser) <
                          maxNFTs
                        )
                          setCurrQty(Number(e.target.value));
                        else setCurrQty(Number(maxNFTs));
                      }}
                      value={currQty}
                    />

                    <button
                      onClick={() => {
                        if (!currentUser) {
                          NotificationManager.error(
                            "Please Connect Wallet First"
                          );
                          return;
                        }
                        console.log(Number(totalMintedByUser), maxNFTs);
                        let mint = currQty + 1;
                        if (mint < 1) mint = 1;
                        if (mint > maxNFTs) mint = maxNFTs;
                        if (Number(mint) <= maxNFTs) {
                          setCurrQty(mint);
                        }
                      }}
                    >
                      +
                    </button>
                  </div>
                </div>
                <div className="mint_btn1 multiminting mt-4">
                  <button
                    className=""
                    type="button"
                    onClick={async (e) => {
                      const wCheck = await WalletConditions(provider);
                      console.log("hereee", wCheck);
                      if (wCheck !== undefined && wCheck !== "") {
                        handleShowAlert(wCheck);
                        return;
                      }
                      await mintTokens();
                    }}
                    disabled={
                      !currentUser || currQty <= 0 || !catInfo?.isActive
                    }
                  >
                    Buy With Crypto
                  </button>
                  {console.log(
                    "buy with crypto====>",
                    !currentUser || currQty <= 0 || !catInfo?.isActive
                  )}
                </div>
                <div className="mint_btn2 multiminting mt-4">
                  <button
                    className=""
                    type="button"
                    disabled={
                      !currentUser || currQty <= 0 || !catInfo?.isActive || true
                    }
                    onClick={async (e) => {
                      setLoading(true);
                      setIsShowModal(true);

                      setLoading(false);
                    }}
                  >
                    Buy With Credit Card
                  </button>
                </div>
              </div>
            </div>
          </div>
          {isShowModal ? (
            <>
              {orderCompleted ? (
                <>
                  <div className="popup-container">
                    <div className="popup_outer">
                      <div className="box">
                        <div class="pop_up">
                          <div class="pop_up_inner">
                            <div class="pop_up_upper"></div>
                            <div class="pop_up_content">
                              <h3>Transaction Completed Successfully.</h3>
                              <h3>View NFT in your Profile</h3>
                              <div class="pop_up_footer_note">
                                <p>NFT Delivery Time</p>
                                {/* <p>Credit Card Purchase: Up to 12 Hrs</p> */}
                                <p>Crypto Purchase: Up to 30 Mins</p>
                                <button
                                  class="pop_up_close_btn"
                                  onClick={() => {
                                    setIsShowModal(false);
                                    setCurrQty(1);
                                    setUserData({
                                      fullName: "",
                                      emailAddress: "",
                                      country: "",
                                      state: "",
                                      postalCode: "",
                                    });
                                    setStateList([]);
                                    setOrderCompleted(0);
                                    setReload(!reload);
                                  }}
                                >
                                  Close
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <PopupModalStripe
                    content={
                      <div className="modal-dialog modal-confirm MintingPage">
                        <div className="modal-content">
                          <div className="modal-body">

                            <div className="content">
                              <div className="wrapper-1">
                                <i className="fa fa-check main-content__checkmark" id="checkmark"></i>
                                <h1>Thank you!</h1>
                                <p>Transaction Completed Successfully. Your NFT can be viewed in 'My NFT' page.</p>
                                <h4 className='dashboard_note'>Note: NFTs bought using credit card will take 4-6 hours to show. NFTs bought using crypto will take 30 min-1 hour to show</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    handleClose={() => {
                      setIsShowModal(false);
                      setCurrQty(1)
                      setUserData({
                        fullName: "",
                        emailAddress: "",
                        country: "",
                        state: "",
                        postalCode: "",
                      })
                      setStateList([]);
                      setOrderCompleted(0)
                      setReload(!reload);
                    }}
                  /> */}
                </>
              ) : (
                <>
                  <PopupModalStripe
                    content={
                      <div className="modal-dialog modal-confirm MintingPage">
                        <div className="modal-content">
                          <div className="modal-body">
                            <div className="customborder customText">
                              <div className="row">
                                <div className="col-md-12 mb-2">
                                  <div class="alert warning da-warning">
                                    <span className="text-muted">
                                      NFT will be minted to
                                      <br />
                                      <strong>{currentUser}</strong>
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="text_content">
                                    <h2>Order Summary</h2>
                                  </div>
                                  <div className="item_section">
                                    <table className="table table-bordered">
                                      <thead>
                                        <tr>
                                          <th>Price</th>
                                          <th>Qty</th>
                                          <th>Total</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr>
                                          <td>
                                            ${parseFloat(NFT_PRICE_IN_DOLLAR)}
                                          </td>
                                          <td>{parseInt(currQty)}</td>
                                          <td>
                                            {" "}
                                            $
                                            {parseFloat(NFT_PRICE_IN_DOLLAR) *
                                              parseInt(currQty)}{" "}
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label for="email">Email</label>
                                    <input
                                      type="email"
                                      autoComplete="off"
                                      className="form-control"
                                      placeholder="Email Address"
                                      value={userData.emailAddress}
                                      onChange={(e) => {
                                        setUserData({
                                          ...userData,
                                          emailAddress: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-6 col-sm-6">
                                  <div className="form-group">
                                    <label for="country">Select Country</label>
                                    <select
                                      value={userData.country}
                                      className="form-control"
                                      onChange={(e) => {
                                        updateState(e.target.value);
                                        setUserData({
                                          ...userData,
                                          country: e.target.value,
                                        });
                                      }}
                                    >
                                      {countryList.map((option) => (
                                        <option value={option.sortname}>
                                          {option.name}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-6 col-sm-6">
                                  <div className="form-group">
                                    <label for="state">Select Area</label>
                                    <select
                                      value={userData.state}
                                      className="form-control"
                                      onChange={(e) => {
                                        setUserData({
                                          ...userData,
                                          state: e.target.value,
                                        });
                                      }}
                                    >
                                      {stateList.length > 0 ? (
                                        <>
                                          <option>Select Area</option>
                                          {stateList.map((option) => (
                                            <option value={option.sortname}>
                                              {option.name}
                                            </option>
                                          ))}
                                        </>
                                      ) : (
                                        <>
                                          <option>Select Country First</option>
                                        </>
                                      )}
                                    </select>
                                  </div>
                                </div>

                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label for="postalcode">Postal Code</label>
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      placeholder="Postal Code"
                                      value={userData.postalCode}
                                      onChange={(e) => {
                                        setUserData({
                                          ...userData,
                                          postalCode: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12">
                                  <div className="form-group">
                                    <label for="name">Name on Card</label>
                                    <input
                                      type="text"
                                      autoComplete="off"
                                      className="form-control"
                                      placeholder="Name on Card"
                                      value={userData.fullName}
                                      onChange={(e) => {
                                        setUserData({
                                          ...userData,
                                          fullName: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row">
                                <div className="col-md-12 mb-4">
                                  <Elements stripe={stripePromise}>
                                    <CheckoutForm
                                      paymentData={
                                        (paymentData = {
                                          userData: userData,
                                          symbol: SYMBOL,
                                          price: NFT_PRICE_IN_DOLLAR,
                                          currency: CURRENCY,
                                          quantity: currQty,
                                          walletAddress: currentUser
                                            ? currentUser
                                            : cookies.selected_account,
                                          contractAddress: contractAddress,
                                        })
                                      }
                                    />
                                  </Elements>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    }
                    handleClose={() => {
                      setIsShowModal(false);
                      setOrderCompleted(0);
                      setUserData({
                        fullName: "",
                        emailAddress: "",
                        country: "",
                        state: "",
                        postalCode: "",
                      });
                      setStateList([]);
                      setCurrQty(1);
                      setReload(!reload);
                    }}
                  />
                </>
              )}
            </>
          ) : (
            <></>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Metamarine;
