import React from 'react'

const MusicIcon = () => {
  return (
    <svg width="512" height="512" viewBox="0 0 512 512" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_14_130)">
    <path d="M126.6 1.90002C125.1 2.90002 122.9 5.10002 121.9 6.60002C120.1 9.40002 120 13.3 120 115.3V221L115.3 218.6C112.6 217.3 106.7 215 102 213.4C94 210.8 92.4 210.6 76 210.6C57.4 210.5 52.5 211.3 39.8 216.4C21.5 223.8 6.99998 238.5 1.99998 254.8C-0.700024 263.5 -0.700024 276.5 1.99998 285.2C6.99998 301.5 21.5 316.2 39.8 323.6C52.3 328.6 57.5 329.5 75 329.5C92.5 329.5 97.7 328.6 110.2 323.6C128.5 316.2 143 301.5 148 285.2C149.9 279 150 275.7 150 166.7V54.7L159 66.1C191.2 106.6 209 141.5 209 164C209 178.9 203.2 190.5 183.5 214.8C180.4 218.6 180 219.8 180 224.8C180 234.5 185.4 240 194.9 240C201.4 240 204.8 238.1 210.7 231.1C223.8 215.8 234.3 197.1 238 182.6C240.5 172.9 240.7 157.3 238.5 146.8C232.6 118.9 213.2 83.7 183.1 46C167.7 26.8 147.8 4.70002 143.4 2.00002C139.2 -0.69998 130.6 -0.69998 126.6 1.90002ZM90.4 242.7C102.1 245.6 113 253.2 117.1 261.2C119.6 266.1 119.6 273.9 117.1 278.8C113 286.8 102.2 294.3 90.3 297.4C77.6 300.7 59.8 299 48.7 293.4C42.5 290.2 35.4 283.7 32.9 278.8C24.5 262.4 42 243.9 68.5 241.1C76 240.3 82.8 240.8 90.4 242.7Z" fill="white"/>
    <path d="M389.5 136C333.4 160.1 284.9 181 281.8 182.4C278 184.2 275.5 186.1 274.1 188.4C272 191.7 272 192.2 272 297.4V403L267.3 400.6C264.6 399.3 258.7 397 254 395.4C246 392.8 244.4 392.6 228 392.6C209.4 392.5 204.5 393.3 191.8 398.4C173.5 405.8 159 420.5 154 436.8C151.3 445.5 151.3 458.5 154 467.2C159 483.5 173.5 498.2 191.8 505.6C204.3 510.6 209.5 511.5 227 511.5C244.5 511.5 249.7 510.6 262.2 505.6C280.5 498.2 295 483.5 300 467.2C301.9 461 302 457.7 302 364.1V267.5L390.3 229.8C438.8 209 479.3 191.8 480.3 191.4C481.9 190.8 482 194.1 482 251.9V313L477.3 310.6C474.6 309.3 468.7 307 464 305.4C456 302.8 454.4 302.6 438 302.6C419.4 302.5 414.5 303.3 401.8 308.4C383.5 315.8 369 330.5 364 346.8C361.3 355.5 361.3 368.5 364 377.2C369 393.5 383.5 408.2 401.8 415.6C414.3 420.6 419.5 421.5 437 421.5C454.5 421.5 459.7 420.6 472.2 415.6C490.5 408.2 505 393.5 510 377.2C512 370.9 512 367.6 512 236.1C512 105.9 511.9 101.4 510.1 98.6C507 93.9 503.2 92 497 92.1C492.1 92.2 481.1 96.7 389.5 136ZM482 143.8V156.5L392.7 194.8C343.7 215.8 303.2 233 302.7 233C302.3 233 302 227.3 302 220.2V207.5L391.3 169.3C440.3 148.3 480.8 131.1 481.3 131C481.7 131 482 136.7 482 143.8ZM452.4 334.7C464.1 337.6 475 345.2 479.1 353.2C481.6 358.1 481.6 365.9 479.1 370.8C476.6 375.7 469.5 382.2 463.3 385.4C448.3 393 425.7 393 410.7 385.4C404.5 382.2 397.4 375.7 394.9 370.8C386.5 354.4 404 335.9 430.5 333.1C438 332.3 444.8 332.8 452.4 334.7ZM242.4 424.7C254.1 427.6 265 435.2 269.1 443.2C271.6 448.1 271.6 455.9 269.1 460.8C266.6 465.7 259.5 472.2 253.3 475.4C238.3 483 215.7 483 200.7 475.4C194.5 472.2 187.4 465.7 184.9 460.8C176.5 444.4 194 425.9 220.5 423.1C228 422.3 234.8 422.8 242.4 424.7Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_14_130">
    <rect width="512" height="512" fill="white"/>
    </clipPath>
    </defs>
    </svg>
    


  )
}

export default MusicIcon