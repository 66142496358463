import React, { useEffect, useState, useRef } from "react";
import { setDefaultBreakpoints } from "react-socks";
import { Link, NavLink } from "react-router-dom";
import transakSDK from "@transak/transak-sdk";
import {
  checkAuthorization,
  checkuseraddress,
  getProfile,
  Login,
  Logout,
  Register,
} from "../../apiServices";
import "react-notifications/lib/notifications.css";
import { Cookies, useCookies } from "react-cookie";
import AllNFTs from "../SVG/AllNFTs";
import Firearmsvg from "../SVG/Firearmsvg";
import { slowRefresh } from "./../../helpers/NotifyStatus";
import "./../components-css/App.css";
import { getCollections, getNFTs, getRPC } from "../../helpers/getterFunctions";
import { getCategory } from "./../../helpers/getterFunctions";
import defaultProfile from "../../assets/images/avatar5.jpg";
import menuIcon from "../../assets/menu.png";
import evt from "./../../events/events";
import { NotificationManager } from "react-notifications";
import { NOTIFICATION_DELAY } from "../../helpers/constants";
import Loader from "../components/Loader";
import { useCallback } from "react";
import debounce from "lodash.debounce";
import { ethers } from "ethers";

import { Web3Auth } from "@web3auth/modal";
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useWalletConnectClient } from "../../contexts/ClientContext";
import ArtIcon from "../SVG/ArtIcon";
import MusicIcon from "../SVG/MusicIcon";
import SportIcon from "../SVG/SportIcon";
import AllNFT from "../SVG/AllNFT";
import eventEmitter from "./../../events/events";

const clientId = process.env.REACT_APP_CLIENT_ID;

var CryptoJS = require("crypto-js");

const Web3 = require("web3");
// web3 lib instance

setDefaultBreakpoints([{ xs: 0 }, { l: 1199 }, { xl: 1200 }]);
export const web3authInstance = async () => {
  console.log("web3 auth instance");
  const web3auth = new Web3Auth({
    clientId,
    web3AuthNetwork: process.env.REACT_APP_NETWORK,
    chainConfig: {
      chainNamespace: "eip155",
      chainId: process.env.REACT_APP_CHAIN_ID, // hex of 80001, polygon testnet
      rpcTarget: process.env.REACT_APP_RPC_URL,
      blockExplorer: process.env.REACT_APP_EXPLORER_URL,
    },
  });
  try {
    console.log("outside initModal starts here --");
    await web3auth.initModal();
    console.log("header outside web3auth.provider web3authInstance", web3auth);
  } catch (error) {
    console.log("header outside Error in initModal web3authInstance", error);
  }

  return web3auth.provider;
};

evt.setMaxListeners(100);
const Header = function () {
  const [cookies, setCookie, removeCookie] = useCookies([]);
  const [account, setAccount] = useState();
  const [userDetails, setUserDetails] = useState();
  const [scolns, setSColns] = useState("none");
  const [sNfts, setSNfts] = useState("none");
  const [showSearchDiv, setShowSearchDiv] = useState("");
  const [searchedText, setShowSearchedText] = useState("");
  const [catg, setCatg] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isBlocked, setIsBlocked] = useState(false);
  const searchBox = useRef(null);
  const [loading, setLoading] = useState(false);
  const [web3auth, setWeb3auth] = useState(null);
  const [provider, setProvider] = useState(null);
  const [showAlert, setShowAlert] = useState();
  const [isCopied, setIsCopied] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [connectLoading, setConnectLoading] = useState(false);
  const { disconnect, connect, web3Provider } = useWalletConnectClient();

  useEffect(() => {
    if (cookies["selected_account"]) {
      setAccount(cookies["selected_account"]);
    }
  }, [cookies["selected_account"]]);

  const init = async () => {
    localStorage.clear();
    try {
      const web3auth = new Web3Auth({
        clientId,
        web3AuthNetwork: process.env.REACT_APP_NETWORK,
        chainConfig: {
          chainNamespace: "eip155",
          chainId: process.env.REACT_APP_CHAIN_ID, // hex of 80001, polygon testnet
          rpcTarget: process.env.REACT_APP_RPC_URL,
          blockExplorer: process.env.REACT_APP_EXPLORER_URL,
        },
      });
      setWeb3auth(web3auth);
      if (localStorage.getItem("userId")) {
        try {
          setAccount(cookies["selected_account"]);
          console.log("account 1 is-------->", account);
        } catch (error) {
          console.log("Error in connect", error);
        }
      } else {
        console.log(
          "local storage userAddress is----->>",
          localStorage.getItem("userId")
        );
      }
      try {
        console.log("in intit modal", web3auth);
        await web3auth.initModal();
        console.log(
          "web3auth.provider in header js oninit is ------------>",
          web3auth.provider
        );
        localStorage.setItem("web3AuthProvider", web3auth.provider);
      } catch (error) {
        console.log("Error in initModal", error);
      }
      console.log("here for testing");
      if (web3auth.provider) {
        console.log("account is---------->");
        setAccount(cookies["selected_account"]);
        console.log(
          "account 2 is-------->",
          account,
          cookies["selected_account"]
        );
        setProvider(web3auth.provider);
      }

      if (localStorage.getItem("userId")) {
        try {
          const web3authProvider = await web3auth.connect();
          setProvider(web3authProvider);
          setAccount(cookies["selected_account"]);
          console.log("account 1 is-------->", account);
        } catch (error) {
          console.log("Error in connect", error);
        }
      } else {
        console.log(
          "local storage userAddress is----->>",
          localStorage.getItem("userId")
        );
      }
    } catch (error) {
      console.error("Error in init is---->", error);
    }
  };
  useEffect(() => {
    console.log(
      "localStorage.getItem",
      localStorage.getItem("DA-connected-provider")
    );
    if (!localStorage.getItem("DA-connected-provider")) {
      console.log("inside init");
      init();
    }
  }, []);

  useEffect(() => {
    async function addClassList() {
      var body = document.body;
      if (showAlert || showLoginPopup) {
        body.classList.add("overflow_hidden");
      } else {
        body.classList.remove("overflow_hidden");
      }
    }
    addClassList();
  }, [showAlert, showLoginPopup]);

  useEffect(() => {
    async function setCategory() {
      const cat = await getCategory();
      setCatg(cat);
    }
    setCategory();
  }, []);

  evt.setMaxListeners(100);

  const connectWallet = async (isWc, isMetamask, chain = "eip155:56") => {
    console.log(
      "connectWallet starts here-------->",
      web3auth,
      provider,
      chain
    );
    console.log("web3 auth status is------->>>>", web3auth?.status);
    try {
      if (web3auth?.status === "connected") {
        console.log("in if block");
        await web3auth?.logout();
      }
    } catch (e) {
      console.log("error in web3 auth logout in connect wallet is----->", e);
    }

    try {
      setConnectLoading(true);
      if (web3auth && !isWc && !isMetamask) {
        let user = null;
        let connectedAddress = null;
        try {
          // Check if web3auth has been initialized
          if (!web3auth) {
            console.error("Web3Auth is not initialized!");
            return;
          }
          
          console.log("here123", web3auth);
          let con = await web3auth.connect();
          console.log("con reuslt is--->", con);
        } catch (errConnect) {
          console.log("errConnectttt", errConnect);
          setConnectLoading(false);
          if (
            errConnect.message === "user closed popup" ||
            errConnect == " user closed popup"
          ) {
            console.log("pop up is closed");
            NotificationManager.error(
              "You have denied connection",
              "",
              NOTIFICATION_DELAY
            );
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          if (errConnect.message?.includes("blockExplorerUrl")) {
            addNetwork();
          }
          refreshState();
        }
        try {
          user = await web3auth.getUserInfo();
          console.log("user info", user);
        } catch (errInit) {
          await web3auth.logout();
          setConnectLoading(false);
          console.log("Error in initModal ", errInit);
        }
        try {
          setProvider(web3auth.provider);
          const web3 = new Web3(web3auth.provider);
          connectedAddress = (await web3.eth.getAccounts())[0]?.toLowerCase();
          console.log("connectedAddress", connectedAddress);
        } catch (errConnect) {
          console.log("errConnectttt", errConnect);
          localStorage.clear();
          setConnectLoading(false);
          await web3auth?.logout();
          console.log("Error in connect ", typeof errConnect?.code);
          if (errConnect?.code === "4001" || errConnect?.code === 4001) {
            NotificationManager.error(
              "User Denied Signature",
              "",
              NOTIFICATION_DELAY
            );
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          }
          refreshState();
        }
        if (connectedAddress) {
          try {
            const provider = new ethers.providers.Web3Provider(
              web3auth.provider
            );
            const siteUrl = process.env.REACT_APP_SITE_URL;
            let nonce = "";
            await provider
              ?.getTransactionCount(connectedAddress)
              .then(async (result) => {
                nonce = CryptoJS.AES.encrypt(
                  JSON.stringify(result),
                  "DASecretKey"
                ).toString();
              });
            console.log("siteUrl", siteUrl, "nonce", nonce);
            const signer = provider.getSigner();

            const originalMessage = `Welcome to Digital Arms!\n\nClick to sign in and accept the Digital Arms Terms of Service: ${siteUrl}/\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:\n${connectedAddress}\n\nNonce:\n${nonce}`;

            const signature = await signer.signMessage(originalMessage);
            if (signature) {
              userAuth(
                connectedAddress,
                signature,
                originalMessage,
                user?.email ? user.email : ""
              );
            } else {
              console.log("disconnect wallet");
              await web3auth?.logout();
              //await onboard.disconnectWallet({ label: wallets[0].label });
              NotificationManager.error(
                "User Denied Signature",
                "",
                NOTIFICATION_DELAY
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              refreshState();
            }

            console.log("signature", signature);
          } catch (error) {
            await web3auth?.logout();
            setConnectLoading(false);
            console.log(
              "denied message is--->",
              error.message,
              typeof error.message
            );
            if (error.message?.includes("user rejected signing")) {
              console.log("in if rejected----->");
              //await onboard.disconnectWallet({ label: wallets[0].label });
              NotificationManager.error(
                "User Denied Signature",
                "",
                NOTIFICATION_DELAY
              );
              setTimeout(() => {
                window.location.reload();
              }, 1000);
              refreshState();
            }
            console.log("Error in connect ", error);
          }
        }
      } else if (isWc) {
        await walletConnect(chain);
      } else if (isMetamask) {
        await metamaskConnect(chain);
      }
    } catch (error) {
      setConnectLoading(false);
      if (error?.message?.toLowerCase()?.includes("already processing")) {
        NotificationManager.error(
          "Please Unlock Your Wallet",
          "",
          NOTIFICATION_DELAY
        );
      } else if (error?.message?.toLowerCase()?.includes("user rejected")) {
        NotificationManager.error(
          "User Denied Signature",
          "",
          NOTIFICATION_DELAY
        );
      } else if (error?.message?.toLowerCase()?.includes("missing provider")) {
        NotificationManager.error(
          "Selected Provided Not Found",
          "",
          NOTIFICATION_DELAY
        );
      }
      localStorage.clear();
      setTimeout(() => {
        window.location.reload();
      }, 2000);

      console.error("Error is Connect 2", error);
    }
  };

  const addNetwork = async () => {
    // Binance smart chain testnet details
    const chainId = process.env.REACT_APP_CHAIN_ID;
    const rpcURL = process.env.REACT_APP_RPC_URL;
    const networkName = "Polygon Amoy";
    const currencyName = "MATIC";
    const currencySymbol = "MATIC";
    const explorerURL = "https://amoy.polygonscan.com/";
    if (!window.ethereum) {
      console.error("Metamask not detected");
      return;
    }
    await window.ethereum.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: chainId,
          chainName: networkName,
          rpcUrls: [rpcURL],
          blockExplorerUrls: [explorerURL],
          nativeCurrency: {
            name: currencyName,
            symbol: currencySymbol, // 2-6 characters long
            decimals: 18,
          },
        },
      ],
    });
    window.location.reload();
  };

  evt.removeAllListeners("connectWallet");
  evt.on("connectWallet", () => {
    console.log("here--->>");

    setShowLoginPopup(!showLoginPopup);
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    // Function for click event
    function handleOutsideClick(event) {
      if (searchBox.current && !searchBox.current.contains(event.target)) {
        setShowSearchDiv("");
        setSearchValue("");
      }
    }
    // Adding click event listener
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, [searchBox]);

  useEffect(() => {
    async function getUserProfileData() {
      if (account && !userDetails) getUserProfile();
    }
    getUserProfileData();
  }, [account, userDetails]);

  const userAuth = async (address, signature, message, email) => {
    console.log("user auth is called");
    try {
      console.log("user dont exist");
      try {
        console.log("email", email);
        const res = await Login(
          address,
          signature,
          message,
          email ? email : ""
        );
        console.log("login api ends");
        if (res?.message === "Wallet Address required") {
          NotificationManager.info(res?.message, "", NOTIFICATION_DELAY);
          await web3auth.logout();
          refreshState();
          return;
        } else if (
          res?.message === "User not found" ||
          res?.message === "Login Invalid"
        ) {
          NotificationManager.error(res?.message, "", NOTIFICATION_DELAY);
          await web3auth.logout();
          refreshState();
          return;
        } else {
          const authorisation = await checkAuthorization();
          console.log("authorisation", authorisation, address);
          if (authorisation) {
            console.log("account authorisation is-------->", address);
            setAccount(address);
            setCookie("selected_account", address, { path: "/" });
            getUserProfile();
            NotificationManager.success(res?.message, "", NOTIFICATION_DELAY);
            localStorage.setItem("DA-connected-provider", "web3Auth");
            //window.location.reload();
            slowRefresh(1000);
            return;
          } else {
            NotificationManager.error(
              "Unauthorised User",
              "",
              NOTIFICATION_DELAY
            );
            await web3auth.logout();
            refreshState();
            return;
          }
        }
      } catch (e) {
        console.log("error", e);
        // await web3auth.logout();
        // refreshState();
        return;
      }
      // }
    } catch (e) {
      console.log("error is--->", e);
      // await web3auth.logout();
      refreshState();
      return;
    }
  };

  const disconnectWallet = async () => {
    console.log("disconnct wallet is called");
    //localStorage.removeItem("openlogin_store");

    console.log("web3 auth is----->", web3auth);

    console.log(
      "there agaim------->>>>",
      localStorage.getItem("DA-connected-provider")
    );
    if (localStorage.getItem("DA-connected-provider") == "walletConnect") {
      try {
        await disconnect();
      } catch (err) {
        console.log("err", err);
      }
      if (
        window.location.pathname === "/myNfts" ||
        window.location.pathname === "/userprofile"
      ) {
        window.location.href = "/";
      }
      NotificationManager.success(
        "User Logged Out Successfully",
        "",
        NOTIFICATION_DELAY
      );
      refreshState();
      slowRefresh(1000);
      return;
    }
    try {
      let res = web3auth?.logout();
      console.log("hereee233333", res);
      localStorage.removeItem("openlogin_store");
    } catch (e) {
      console.log("error in logout", e);
    }

    await Logout(cookies["selected_account"]);
    refreshState();
    if (
      window.location.pathname === "/myNfts" ||
      window.location.pathname === "/userprofile"
    ) {
      window.location.href = "/";
    }
    NotificationManager.success(
      "User Logged Out Successfully",
      "",
      NOTIFICATION_DELAY
    );
    slowRefresh(1000);
  };

  evt.removeAllListeners("disconnectWallet");
  evt.on("disconnectWallet", () => {
    disconnectWallet();
  });

  const refreshState = () => {
    removeCookie("selected_account", { path: "/" });
    // removeCookie("chain_id", { path: "/" });
    removeCookie("label", { path: "/" });
    localStorage.clear();
    removeCookie("DA-connected-provider");
    console.log("account is set null");
    setAccount("");
  };

  evt.removeAllListeners("refreshState");
  evt.on("refreshState", () => {
    refreshState();
    slowRefresh(1000);
  });

  const getUserProfile = async () => {
    const profile = await getProfile();
    localStorage.setItem("userId", profile?.data?._id);
    setUserDetails(profile?.data);
  };

  eventEmitter.removeAllListeners("fetchUserDetails");
  eventEmitter.on("fetchUserDetails", () => {
    setUserDetails("");
    getUserProfile();
  });

  useEffect(() => {
    setLoading(true);
    const fetch = async () => {
      const reqData = {
        page: 1,
        limit: 12,
        searchText: searchValue,
      };
      const colns = await getCollections(reqData);
      console.log("colns", colns);
      if (colns && colns?.length > 3) {
        let data = colns?.slice(0, 3);
        console.log("data in cols=====>", data);
        setSColns(data);
      } else {
        setSColns(colns);
      }

      const nfts = await getNFTs(reqData);
      console.log("nfts", nfts);
      if (nfts && nfts?.length > 3) {
        let data = nfts?.slice(0, 3);
        console.log("data in cols=====>", data);
        setSNfts(data);
      } else {
        setSNfts(nfts);
      }

      if (!colns || !nfts) {
        scolns("none");
        sNfts("none");
        setShowSearchDiv("");
      }
      setLoading(false);
    };
    if (searchValue) fetch();
  }, [searchValue]);

  const handleSearch = async (e) => {
    const { value } = e.target;
    if (value !== "" && value?.trim() !== "") {
      setSearchValue(value);
      setShowSearchDiv("active");
      setShowSearchedText(value);
      setSColns("none");
      setSNfts("none");
    } else {
      setSearchValue("");
      setShowSearchDiv("");
    }
  };

  const addBnBFunds = async () => {
    let walletAccount = account;
    if (!walletAccount) {
      return;
    }
    let transak = new transakSDK({
      // (PRODUCTION) Live Keys
      // apiKey: '91368e84-9792-4123-9880-5b54bc4f7611',
      // environment: 'PRODUCTION',

      // (STAGING) Test Keys
      apiKey: "6b3a30bd-5150-4416-afdc-5607e65abffa",
      environment: "STAGING",
      defaultCryptoCurrency: "MATIC",
      cryptoCurrencyList: "MATIC",
      walletAddress: walletAccount, // Your customer's wallet address
      themeColor: "000000", // App theme colorsetWebProvider
      fiatCurrency: "USD", // INR/GBP
      // email: 'deepak.decrypt@gmail.com', // Your customer's email address
      networks: "amoy", // Network
      redirectURL: window.location.origin,
      hostURL: window.location.origin,
      widgetHeight: "550px",
      widgetWidth: "450px",
    });
    transak.init();
    transak.on(transak.ALL_EVENTS, (data) => {
      console.log(data);
    });
    transak.on(transak.EVENTS.TRANSAK_ORDER_SUCCESSFUL, (orderData) => {
      console.log(orderData);
      transak.close();
    });
  };

  const optimisedSearchHandle = useCallback(debounce(handleSearch, 300), []);

  const walletConnect = async (chain) => {
    try {
      console.log("walletConnect called");
      let ress = await connect(chain);
      setLoading(true);
      console.log("res", ress);
      if (ress == false) {
        console.log("Error while connecting..", ress);
        setLoading(false);
        return;
      }

      // let sign = await testSignMessage(web3Provider)
      console.log("web3Provider", web3Provider, ress?.ethereumProvider);
      let provider = new ethers.providers.Web3Provider(ress?.ethereumProvider);
      console.log("provider", provider);
      const siteUrl = process.env.REACT_APP_SITE_URL;
      let nonce = "";
      await provider
        ?.getTransactionCount(ress?._accounts[0])
        .then(async (result) => {
          nonce = CryptoJS.AES.encrypt(
            JSON.stringify(result),
            "DASecretKey"
          ).toString();
        });
      const signer = provider.getSigner();
      const originalMessage = `Welcome to Digital Arms!\n\nClick to sign in and accept the Digital Arms Terms of Service: ${siteUrl}/\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:\n${ress?._accounts[0]}\n\nNonce:\n${nonce}`;
      const signature = await signer.signMessage(originalMessage);
      if (signature) {
        // userAuth(accounts[0], sign?.result, "Hello World", "");
        console.log("user auth is called", ress?._accounts[0]);
        try {
          console.log("user dont exist");
          try {
            const res = await Login(
              ress?._accounts[0],
              signature,
              originalMessage,
              ""
            );
            console.log("login api ends");
            if (res?.message === "Wallet Address required") {
              NotificationManager.info(res?.message, "", NOTIFICATION_DELAY);
              await disconnect();
              setLoading(false);
              refreshState();
              return;
            } else if (
              res?.message === "User not found" ||
              res?.message === "Login Invalid"
            ) {
              NotificationManager.error(res?.message, "", NOTIFICATION_DELAY);
              await disconnect();
              setLoading(false);
              refreshState();
              return;
            } else {
              const authorisation = await checkAuthorization();
              console.log("authorisation", authorisation, ress?._accounts[0]);
              if (authorisation) {
                console.log("account authorisation is-------->", account);
                setAccount(ress?._accounts[0]);
                setCookie("selected_account", ress?._accounts[0], {
                  path: "/",
                });
                getUserProfile();
                NotificationManager.success(
                  res?.message,
                  "",
                  NOTIFICATION_DELAY
                );
                localStorage.setItem("DA-connected-provider", "walletConnect");
                setLoading(false);
                //window.location.reload();
                slowRefresh(1000);
                return;
              } else {
                NotificationManager.error(
                  "Unauthorised User",
                  "",
                  NOTIFICATION_DELAY
                );
                await disconnect();
                setLoading(false);
                refreshState();
                return;
              }
            }
          } catch (e) {
            await disconnect();
            setLoading(false);
            refreshState();
            return;
          }
          // }
        } catch (e) {
          console.log("error is--->", e);
          await disconnect();
          setLoading(false);
          refreshState();
          return;
        }
      } else {
        console.log("disconnect wallet");
        await disconnect();
        setLoading(false);
        //await onboard.disconnectWallet({ label: wallets[0].label });
        NotificationManager.error(
          "User Denied Signature",
          "",
          NOTIFICATION_DELAY
        );
        setTimeout(() => {
          window.location.reload();
        }, 1000);
        refreshState();
      }
      console.log("signature", signature);
    } catch (Err) {
      console.log("err", Err);
    }
  };

  const metamaskConnect = async (chain) => {
    setLoading(true);

    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const accounts = await provider.send("eth_requestAccounts", []);
    const siteUrl = process.env.REACT_APP_SITE_URL;
    let nonce = "";
    await provider?.getTransactionCount(accounts[0]).then(async (result) => {
      nonce = CryptoJS.AES.encrypt(
        JSON.stringify(result),
        "DASecretKey"
      ).toString();
    });
    const signer = provider.getSigner();
    const originalMessage = `Welcome to Digital Arms!\n\nClick to sign in and accept the Digital Arms Terms of Service: ${siteUrl}/\n\nThis request will not trigger a blockchain transaction or cost any gas fees.\n\nYour authentication status will reset after 24 hours.\n\nWallet address:\n${accounts[0]}\n\nNonce:\n${nonce}`;
    const signature = await signer.signMessage(originalMessage);
    if (signature) {
      // userAuth(accounts[0], sign?.result, "Hello World", "");
      console.log("user auth is called", accounts[0]);
      try {
        console.log("user dont exist");
        try {
          const res = await Login(accounts[0], signature, originalMessage, "");
          console.log("login api ends");
          if (res?.message === "Wallet Address required") {
            NotificationManager.info(res?.message, "", NOTIFICATION_DELAY);
            await disconnect();
            setLoading(false);
            refreshState();
            return;
          } else if (
            res?.message === "User not found" ||
            res?.message === "Login Invalid"
          ) {
            NotificationManager.error(res?.message, "", NOTIFICATION_DELAY);
            await disconnect();
            setLoading(false);
            refreshState();
            return;
          } else {
            const authorisation = await checkAuthorization();
            console.log("authorisation", authorisation, accounts[0]);
            if (authorisation) {
              console.log("account authorisation is-------->", account);
              setAccount(accounts[0]);
              setCookie("selected_account", accounts[0], { path: "/" });
              getUserProfile();
              NotificationManager.success(res?.message, "", NOTIFICATION_DELAY);
              localStorage.setItem("DA-connected-provider", "metamask");
              setLoading(false);
              //window.location.reload();
              slowRefresh(1000);
              return;
            } else {
              NotificationManager.error(
                "Unauthorised User",
                "",
                NOTIFICATION_DELAY
              );
              await disconnect();
              setLoading(false);
              refreshState();
              return;
            }
          }
        } catch (e) {
          await disconnect();
          setLoading(false);
          refreshState();
          return;
        }
        // }
      } catch (e) {
        console.log("error is--->", e);
        await disconnect();
        setLoading(false);
        refreshState();
        return;
      }
    } else {
      console.log("disconnect wallet");
      await disconnect();
      setLoading(false);
      //await onboard.disconnectWallet({ label: wallets[0].label });
      NotificationManager.error(
        "User Denied Signature",
        "",
        NOTIFICATION_DELAY
      );
      setTimeout(() => {
        window.location.reload();
      }, 1000);
      refreshState();
    }
    console.log("signature", signature);
  };
  let Logo = process.env.REACT_APP_ASSETS_BASE_PATH + "assets/images/Link.png";
  return (
    <>
      {showAlert && (
        <div className="popup-box custom_pop">
          <div class="cust_modal-content position-relative">
            <span
              class="close-button"
              onClick={() => {
                setConnectLoading(false);
                setShowAlert(!showAlert);
              }}
            >
              X
            </span>
            <div className="logout-text">Are You Sure You Want to Logout?</div>
            <div className="d-flex justify-content-center">
              <button
                className="main_btn logout_btn"
                onClick={async () => {
                  await disconnectWallet();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        </div>
      )}
      {showLoginPopup && (
        <div className="popup-box custom_pop">
          <div class="cust_modal-content position-relative p-5">
            <span
              class="close-button"
              onClick={() => {
                setConnectLoading(false);
                setShowLoginPopup(!showLoginPopup);
              }}
            >
              X
            </span>
            <div className="connect-heading">Welcome to Marketplace</div>
            <div
              style={{
                color: "#fff",
                fontSize: "1.5em",
                lineHeight: "1.5em",
                paddingBottom: "19px",
                fontFamily: "Montserrat",
                fontWeight: "400",
                textAlign: "center",
              }}
            >
              Select your preferred login option
            </div>
            <div className="d-flex align-items-center justify-content-center flex-wrap  ">
              {/* {connectLoading && <Loader />} */}
              {/* <button
                onClick={async () => {
                  console.log(" process.env.REACT_APP_NETWORK_ID", process.env.REACT_APP_NETWORK_ID)
                  !account ? (await connectWallet(true, false, ("eip155:" + process.env.REACT_APP_NETWORK_ID))) : setShowAlert(true);
                }}
                className="Web3Auth_btn btn-banner m-3"
                tabIndex="-1"
                style={{ minWidth: "194px" }}
              // disabled={connectLoading}
              >
                {" "}
                Wallet Connect{" "}
              </button> */}

              <button
                onClick={async () => {
                  !account
                    ? await connectWallet(false, false, "")
                    : setShowAlert(true);
                }}
                className="Web3Auth_btn btn-banner m-3"
                tabIndex="-1"
                style={{ minWidth: "194px" }}
                // disabled={connectLoading}
                id="social-wallet"
              >
                {" "}
                Social Account{" "}
              </button>

              <button
                onClick={async () => {
                  !account
                    ? await connectWallet(false, true, "")
                    : setShowAlert(true);
                }}
                className="Web3Auth_btn btn-banner m-3"
                tabIndex="-1"
                style={{ minWidth: "194px" }}
                // disabled={connectLoading}
                id="metamask-wallet"
              >
                {" "}
                Metamask{" "}
              </button>
            </div>
          </div>
        </div>
      )}
      <header id="myHeader">
        <nav className="navbar navbar-expand-xl">
          <div className="nav-container container">
            <Link className="navbar-brand" to="/">
              <img
                src={Logo}
                className="img-fluid d-block"
                alt=""
                width="200"
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              {/* <span className='navbar-toggler-icon'></span> */}

              <img src={menuIcon} alt="" />
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <div className="d-flex navbar_form">
                <input
                  className="form-control me-2 "
                  type="text"
                  placeholder="Search item here..."
                  aria-label="Search"
                  ref={searchBox}
                  maxLength={70}
                  onChange={optimisedSearchHandle}
                  onKeyPress={(e) => {
                    const { value } = e.target;
                    if (value !== "") {
                      if (e.key === "Enter") {
                        if (sNfts?.length > 0)
                          window.location.href = `/marketplace/${searchedText}`;
                        if (scolns?.length > 0)
                          window.location.href = `/marketplacecollection/${searchedText}`;
                      }
                    }
                  }}
                />
                <button
                  className="search_btn"
                  type="submit"
                  onClick={() => {
                    if (searchedText !== "") {
                      if (sNfts?.length > 0)
                        window.location.href = `/marketplace/${searchedText}`;
                      if (scolns?.length > 0)
                        window.location.href = `/marketplacecollection/${searchedText}`;
                    }
                  }}
                >
                  <img
                    src={"../img/search2.svg"}
                    alt=""
                    height={"50%"}
                    width={"50%"}
                  />
                </button>
              </div>
              {!loading &&
              searchValue !== "" &&
              ((sNfts !== "none" && sNfts?.length > 0 && searchedText) ||
                (scolns !== "none" && scolns?.length > 0 && searchedText)) ? (
                <div className={`searched_div ${showSearchDiv}`}>
                  {scolns?.length > 0 && <p>Collections</p>}

                  <ul>
                    {console.log(
                      "scolns && scolns?.length > 0",
                      scolns,
                      scolns?.length > 0
                    )}
                    {!loading &&
                      scolns &&
                      scolns !== "none" &&
                      scolns?.length > 0 &&
                      scolns?.map((item) => {
                        return (
                          <a href={`/collection/${item._id}`}>
                            <li> {item.name}</li>
                          </a>
                        );
                      })}
                  </ul>
                  {!loading &&
                    scolns &&
                    scolns !== "none" &&
                    scolns?.length > 3 && (
                      <a
                        className="view_all_bdr search_view_all mb-3"
                        href={`/marketplaceCollection/${searchedText}`}
                      >
                        View All
                      </a>
                    )}
                  {sNfts?.length > 0 && <p>NFTs</p>}
                  <ul>
                    {!loading &&
                      sNfts &&
                      sNfts !== "none" &&
                      sNfts?.length > 0 &&
                      sNfts?.map((item) => {
                        return (
                          <a href={`/NFTdetails/${item.id}`}>
                            <li>{item.name}</li>
                          </a>
                        );
                      })}
                  </ul>
                  {!loading &&
                    sNfts &&
                    sNfts !== "none" &&
                    sNfts?.length > 3 && (
                      <a
                        className="view_all_bdr search_view_all"
                        href={`/marketplace/${searchedText}`}
                      >
                        View All
                      </a>
                    )}
                </div>
              ) : (
                ""
              )}
              {!loading &&
              scolns !== "none" &&
              scolns?.length <= 0 &&
              sNfts !== "none" &&
              sNfts?.length <= 0 &&
              searchValue !== "" ? (
                <div className={`searched_div active`}>
                  <span className="d-block no_data_text">No Result Found</span>
                </div>
              ) : (
                ""
              )}
              {loading &&
              (sNfts === "none" || scolns == "none") &&
              searchValue !== "" ? (
                <div className={`searched_div active`}>
                  <Loader />
                </div>
              ) : (
                ""
              )}
              <ul className="navbar-nav me-auto align-items-center mb-2 mb-lg-0">
                <li className="nav-item">
                  <NavLink
                    className="nav-link"
                    aria-current="page"
                    to="/marketplace"
                  >
                    Marketplace
                  </NavLink>
                  <ul className="sub_menu">
                    <li>
                      <NavLink to={"/marketplace"} className="sub-items">
                        <AllNFT />
                        All NFTs
                      </NavLink>
                    </li>
                    {catg?.length > 0
                      ? catg?.map((c, key) => {
                          return (
                            <li key={key}>
                              <NavLink
                                to={`/marketplacecollection/${c.name}`}
                                className="sub-items"
                              >
                                {c.name === "Art" ? (
                                  <ArtIcon />
                                ) : c.name === "Music" ? (
                                  <MusicIcon />
                                ) : (
                                  <SportIcon />
                                )}
                                {c.name}
                              </NavLink>
                            </li>
                          );
                        })
                      : ""}
                  </ul>
                </li>
                <li className="nav-item">
                  <NavLink className="nav-link" to={"/marketplacecollection"}>
                    Collections
                  </NavLink>
                </li>
                {/* <li className="nav-item">
                <a className="nav-link">Resources</a>
                <ul className="sub_menu">
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BLOG_URL}help-center`}
                      className="sub-items"
                      target="blank"
                    >
                      Help Center
                    </a>
                  </li>

                  <li>
                    <a
                      href={`${process.env.REACT_APP_BLOG_URL}gaming-partners`}
                      className="sub-items"
                      target="blank"
                    >
                      Gaming Partners
                    </a>
                  </li>
                  <li>
                    <a
                      href={`${process.env.REACT_APP_BLOG_URL}blog`}
                      className="sub-items"
                      target="blank"
                    >
                      Announcements
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.hunter-token.com/"
                      className="sub-items"
                      target="blank"
                    >
                      About $HNTR Token
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://staking-digitalarms.vercel.app/"
                      className="sub-items"
                      target="blank"
                    >
                      HNTR Token & NFT Staking
                    </a>
                  </li>
                  <ul className="subsocial-icons mt-3">
                    <li><a class="" href="https://www.facebook.com/digitalarmsnft" target="_blank" rel="noreferrer"><i class="fa-brands fa-facebook-f fa-lg"></i></a></li>
                    <li><a class="" href="https://twitter.com/DigitalArmsNFT" target="_blank" rel="noreferrer"><i class="fa-brands fa-twitter fa-lg"></i></a></li>
                    <li><a class="" href="https://www.linkedin.com/company/digital-arms-hntr-token/" target="_blank" rel="noreferrer"><i class="fa-brands fa-linkedin-in fa-lg"></i></a></li>
                    <li><a class="" href="https://discord.gg/digital-arms" target="_blank" rel="noreferrer"><i class="fa-brands fa-discord fa-lg"></i></a></li>
                    <li><a class="" href="https://t.me/huntertokenofficial" target="_blank" rel="noreferrer"><i class="fa-brands fa-telegram fa-lg"></i></a></li>
                  </ul>
                </ul>
              </li> */}
                {!userDetails ? (
                  <>
                    <li className="nav-item">
                      <button
                        onClick={() => {
                          !account
                            ? setShowLoginPopup(true)
                            : setShowAlert(true);
                        }}
                        className="main_btn"
                        tabIndex="-1"
                      >
                        {!account
                          ? "Connect Wallet"
                          : account.slice(0, 4) + "..." + account.slice(38, 42)}
                      </button>
                    </li>
                  </>
                ) : (
                  <>
                    <li className="nav-item">
                      <div tabIndex="-1" className="profile_pic">
                        <img
                          src={
                            userDetails?.profileIcon
                              ? userDetails.profileIcon
                              : defaultProfile
                          }
                          className="img-fluid hunter_fav"
                          alt="favicon"
                        />
                      </div>

                      <ul className="sub_menu">
                        <li className="sub_pdd">
                          <span className="Connected">Connected </span>
                          <div className="sub_div">
                            <div className="profile_pic mr-3">
                              <img
                                src={
                                  userDetails?.profileIcon
                                    ? userDetails.profileIcon
                                    : defaultProfile
                                }
                                className="img-fluid hunter_fav"
                                alt="favicon"
                              />
                            </div>
                            <div className="">
                              <h6>
                                {userDetails?.username === ""
                                  ? "unnamed"
                                  : userDetails?.username}
                              </h6>
                              <p>
                                {account?.slice(0, 4) +
                                  "..." +
                                  account?.slice(38, 42)}
                              </p>
                            </div>
                          </div>
                        </li>
                        <li>
                          <NavLink to={"/userprofile"} className="sub-items">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10 0C11.3261 0 12.5979 0.526784 13.5355 1.46447C14.4732 2.40215 15 3.67392 15 5C15 6.32608 14.4732 7.59785 13.5355 8.53553C12.5979 9.47322 11.3261 10 10 10C8.67392 10 7.40215 9.47322 6.46447 8.53553C5.52678 7.59785 5 6.32608 5 5C5 3.67392 5.52678 2.40215 6.46447 1.46447C7.40215 0.526784 8.67392 0 10 0ZM10 12.5C15.525 12.5 20 14.7375 20 17.5V20H0V17.5C0 14.7375 4.475 12.5 10 12.5Z"
                                fill="#EF981D"
                              />
                            </svg>{" "}
                            My Account
                          </NavLink>
                        </li>
                        <li>
                          <NavLink to={`/myNfts`} className="sub-items">
                            <svg
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M8.57143 5.71429H4.28571V8.57143H8.57143V5.71429ZM0 4.28571C0 3.14907 0.451529 2.05898 1.25526 1.25526C2.05898 0.451529 3.14907 0 4.28571 0H12.8571V20H4.28571C3.14907 20 2.05898 19.5485 1.25526 18.7447C0.451529 17.941 0 16.8509 0 15.7143V4.28571ZM2.85714 5.71429V8.57143C2.85714 8.95031 3.00765 9.31367 3.27556 9.58158C3.54347 9.84949 3.90683 10 4.28571 10H8.57143C8.95031 10 9.31367 9.84949 9.58158 9.58158C9.84949 9.31367 10 8.95031 10 8.57143V5.71429C10 5.33541 9.84949 4.97204 9.58158 4.70413C9.31367 4.43622 8.95031 4.28571 8.57143 4.28571H4.28571C3.90683 4.28571 3.54347 4.43622 3.27556 4.70413C3.00765 4.97204 2.85714 5.33541 2.85714 5.71429ZM3.57143 11.4286C3.38199 11.4286 3.20031 11.5038 3.06635 11.6378C2.9324 11.7717 2.85714 11.9534 2.85714 12.1429C2.85714 12.3323 2.9324 12.514 3.06635 12.6479C3.20031 12.7819 3.38199 12.8571 3.57143 12.8571H9.28571C9.47515 12.8571 9.65684 12.7819 9.79079 12.6479C9.92475 12.514 10 12.3323 10 12.1429C10 11.9534 9.92475 11.7717 9.79079 11.6378C9.65684 11.5038 9.47515 11.4286 9.28571 11.4286H3.57143ZM2.85714 15C2.85714 15.1894 2.9324 15.3711 3.06635 15.5051C3.20031 15.639 3.38199 15.7143 3.57143 15.7143H9.28571C9.47515 15.7143 9.65684 15.639 9.79079 15.5051C9.92475 15.3711 10 15.1894 10 15C10 14.8106 9.92475 14.6289 9.79079 14.4949C9.65684 14.361 9.47515 14.2857 9.28571 14.2857H3.57143C3.38199 14.2857 3.20031 14.361 3.06635 14.4949C2.9324 14.6289 2.85714 14.8106 2.85714 15ZM14.2857 20H15.7143C16.8509 20 17.941 19.5485 18.7447 18.7447C19.5485 17.941 20 16.8509 20 15.7143V14.2857H14.2857V20ZM20 12.8571V7.14286H14.2857V12.8571H20ZM20 5.71429V4.28571C20 3.14907 19.5485 2.05898 18.7447 1.25526C17.941 0.451529 16.8509 0 15.7143 0H14.2857V5.71429H20Z"
                                fill="#EF981D"
                              />
                            </svg>{" "}
                            My NFTs
                          </NavLink>
                        </li>
                        <li>
                          <a
                            href="#"
                            className="sub-items"
                            onClick={() => {
                              setShowAlert(true);
                            }}
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M12 0C5.38267 0 0 5.38267 0 12V12.0013L6.66667 6.668V10.668H16V13.3347H6.66667V17.3347L0 12.0013C0.00133333 18.6187 5.38267 24 12 24C18.6173 24 24 18.6173 24 12C24 5.38267 18.6173 0 12 0Z"
                                fill="#EF981D"
                              />
                            </svg>{" "}
                            Disconnect
                          </a>
                        </li>
                      </ul>
                    </li>
                    <li className="nav-item">
                      <button
                        className="square_yello"
                        tabIndex="-1"
                        onClick={() => {
                          setShowAlert(true);
                        }}
                      >
                        <img src="../img/edit.png" alt="edit" />{" "}
                        {account?.slice(0, 4) + "..." + account?.slice(38, 42)}
                      </button>
                    </li>
                    <CopyToClipboard
                      className="copy-icon"
                      text={account}
                      onCopy={() => {
                        setIsCopied(true);
                        setTimeout(() => {
                          setIsCopied(false);
                        }, 1000);
                        NotificationManager.success(
                          "Copied!!",
                          "",
                          NOTIFICATION_DELAY
                        );
                      }}
                      title="Copy Wallet Address"
                    >
                      <svg
                        width="21"
                        height="24"
                        viewBox="0 0 21 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 21V22.875C15 23.4963 14.4963 24 13.875 24H1.125C0.503672 24 0 23.4963 0 22.875V5.625C0 5.00367 0.503672 4.5 1.125 4.5H4.5V18.375C4.5 19.8225 5.67755 21 7.125 21H15ZM15 4.875V0H7.125C6.50367 0 6 0.503672 6 1.125V18.375C6 18.9963 6.50367 19.5 7.125 19.5H19.875C20.4963 19.5 21 18.9963 21 18.375V6H16.125C15.5063 6 15 5.49375 15 4.875ZM20.6705 3.42052L17.5795 0.329484C17.3685 0.11852 17.0824 1.55998e-06 16.784 0L16.5 0V4.5H21V4.21598C21 3.91763 20.8815 3.63149 20.6705 3.42052Z"
                          fill="#fff"
                        />
                      </svg>
                    </CopyToClipboard>

                    {/* <img src={CopyIcon} className="copy-icon" alt='edit' title="Copy Wallet Address" />{" "} */}
                  </>
                )}
              </ul>
              {/* <p>expand object select mention floor lumber unaware already narrow industry visit bundle</p> */}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};
export default Header;
