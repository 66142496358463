import { BigNumber } from "bignumber.js";
import { ethers } from "ethers";
import {
  GENERAL_DATE,
  GENERAL_TIMESTAMP,
  MAX_ALLOWANCE_AMOUNT,
  NOTIFICATION_DELAY,
  ZERO_ADDRESS,
} from "./constants";
import erc20Abi from "./../config/abis/erc20.json";
import erc1155Abi from "./../config/abis/simpleERC1155.json";
import {
  buildBuyerOrder,
  getCurrentProvider,
  GetOwnerOfToken,
  getPaymentTokenInfo,
  getUsersTokenBalance,
} from "./getterFunctions";
import {
  exportInstance,
  getOrderDetails,
  UpdateOrder,
  DeleteOrder,
  createOrder,
  createBidNft,
  createOfferNFT,
  updateBidNft,
  acceptOffer,
  UpdateStatus,
  InsertHistory,
  deleteBids,
  FetchInstance,
} from "../apiServices";
import marketPlaceABI from "./../config/abis/marketplace.json";
import contracts from "./../config/contracts";
import { buildSellOrder, getSignature } from "./getterFunctions";
import erc721Abi from "./../config/abis/simpleERC721.json";
import { isEmptyObject } from "jquery";
import evt from "../events/events";
import { slowRefresh } from "./NotifyStatus";
import moment from "moment";
import { NotificationManager } from "react-notifications";
import { web3authInstance } from "../components/menu/header";
import { WalletConditions } from "../components/components/WalletConditions";

export const handleBuyNft = async (
  id,
  isERC721,
  account,
  qty = 1,
  historyData,
  web3Provider
) => {
  let order;
  let details;
  let marketplace;
  try {
    console.log("buy nft",  id,
      isERC721,
      account,
      qty = 1,
      historyData,
      web3Provider)
    if (!id || id === undefined) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }
    order = await buildSellOrder(id);
    if (order === false) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }
    details = await getOrderDetails({ orderID: id });
    console.log("details**************", details)
  } catch (e) {
    console.log("error in API", e);
    return false;
  }
  if (!order[1]) {
    return false;
  }
  let sellerOrder = [];
  let buyerOrder = [];
  // let amount = new BigNumber(order[6]?.toString())
  //   .multipliedBy(new BigNumber(qty?.toString()))
  //   ?.toString();
  console.log("creating contract instance")
  let NFTcontract = await exportInstance(
    order[1],
    isERC721 ? erc721Abi.abi : erc1155Abi.abi,
    web3Provider
  );

  for (let key = 0; key < 11; key++) {
    switch (key) {
      case 0:
        if (isERC721) {
          sellerOrder.push(order[key]?.toLowerCase());
          buyerOrder.push(account?.toLowerCase());
          break;
        } else {
          sellerOrder.push(order[key]);
          buyerOrder.push(account?.toLowerCase());
          break;
        }
      case 1:
        sellerOrder.push(order[key]);
        buyerOrder.push(order[key]);
        break;
      case 3:
        if (isERC721) {
          sellerOrder.push(order[key]);
          buyerOrder.push(order[key]);
        } else {
          sellerOrder.push(order[key]);
          buyerOrder.push(Number(qty));
        }

        break;
      case 5:
        sellerOrder.push(order[key]);
        buyerOrder.push(order[key]);
        break;
      case 6:
        if (isERC721) {
          sellerOrder.push(order[key]);
          buyerOrder.push(order[key]);
        } else {
          buyerOrder.push(order[key]);
          sellerOrder.push(order[key]);
        }

        break;
      case 8:
        sellerOrder.push([parseInt(order[2])]);
        buyerOrder.push([parseInt(order[2])]);
        break;
      case 9:
        sellerOrder.push([order[3]]);
        buyerOrder.push([order[3]]);
        break;
      default:
        sellerOrder.push(parseInt(order[key]));
        buyerOrder.push(parseInt(order[key]));
    }
  }

  console.log("seller orderrr", sellerOrder, buyerOrder);

  try {
    if (!sellerOrder[0]) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }

    let usrHaveQuantity = await GetOwnerOfToken(
      sellerOrder[1],
      sellerOrder[2],
      1,
      sellerOrder[0]
    );

    if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      return false;
    }
  } catch (e) {
    console.log("error", e);
    NotificationManager.error(
      "Error while buying NFT, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }

  evt.emit("txn-status", "token-approval-initiated");
  if (buyerOrder[5] !== ZERO_ADDRESS) {
    try {
      let allowance = await getPaymentTokenInfo(buyerOrder[0], buyerOrder[5]);
      if (
        new BigNumber(buyerOrder[6]).isGreaterThan(
          new BigNumber(allowance.balance)
        )
      ) {
        evt.emit("txn-error", "Error");
        NotificationManager.error(
          "Don't have sufficient funds",
          "",
          NOTIFICATION_DELAY
        );
        return false;
      }
      if (
        new BigNumber(allowance.allowance).isLessThan(
          new BigNumber(buyerOrder[6]?.toString())
        )
      ) {
        try {
          let approveRes = await handleApproveToken(
            buyerOrder[0],
            buyerOrder[5],
            web3Provider
          );
          if (approveRes === false) {
            evt.emit("txn-error", "user-denied-token-approval");
            return false;
          }
        } catch (e) {
          console.log("error", e);
          if (
            e.code === 4001 ||
            e.code === 5001 ||
            JSON.stringify(e).includes("user rejected transaction") ||
            e.toString().includes("Error: User canceled")
          ) {
            evt.emit("txn-error", "user-denied-transaction");
            return false;
          }
          NotificationManager.error(
            "Error while buying NFT, Something might have changed",
            "",
            NOTIFICATION_DELAY
          );
          evt.emit("txn-error", "Error");
          return false;
        }
      }
    } catch (err) {
      evt.emit("txn-error", "transaction-failed");
      NotificationManager.error(
        "Error while buying NFT, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );
      console.log("error", err);
      return false;
    }
  }
  evt.emit("txn-status", "token-approval-success");

  evt.emit("txn-status", "approval-initiated");
  let approval = await NFTcontract.isApprovedForAll(
    sellerOrder[0],
    contracts.MARKETPLACE
  );
  console.log("approval is ------>", approval);
  if (!approval) {
    evt.emit("txn-error", "Error");
    NotificationManager.error(
      "Seller didn't approved marketplace",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }
  evt.emit("txn-status", "approval-succeeded");
  let signature = details.signature;
  let options;

  try {
    marketplace = await exportInstance(
      contracts.MARKETPLACE,
      marketPlaceABI.abi,
      web3Provider
    );
    console.log("555555",  sellerOrder,

      buyerOrder,
      signature);
    evt.emit("txn-status", "transaction-initiated");

    try {
      console.log(
        "6666666",
        sellerOrder,

        buyerOrder,
        signature
      );
      let es = await marketplace.estimateGas.completeOrder(
        sellerOrder,
        signature,
        buyerOrder,
        signature
      );
      console.log("6666666");

      let priceLimit = new BigNumber(es.toString()).plus(
        new BigNumber(es.toString()).multipliedBy(0.1)
      );

      // console.log("amount", amount)
      options = {
        from: account,
        // gasPrice: 10000000000,
        gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
        value: sellerOrder[5] === ZERO_ADDRESS ? buyerOrder[6]?.toString() : 0,
      };
    } catch (err) {
      console.log("err in estimate", err);
    }

    try {
      let ress = await buildSellOrder(id);
      console.log("resu of build sell order is", ress);
      if (ress !== false) {
        console.log("in 11111");
        let checkCallStatic = await marketplace.callStatic.completeOrder(
          sellerOrder,
          signature,
          buyerOrder,
          signature
        );

        console.log("checkCallStatic", checkCallStatic);
        let completeOrder;
        if (checkCallStatic) {
          try {
            completeOrder = await marketplace.completeOrder(
              sellerOrder,
              signature,
              buyerOrder,
              signature,
              options
            );
          } catch (e) {
            console.log("error 261", e);
            if (
              e.code === 4001 ||
              e.code === 5001 ||
              JSON.stringify(e).includes("user rejected transaction") ||
              e.toString().includes("Error: User canceled")
            ) {
              evt.emit("txn-error", "user-denied-transaction");
              return false;
            }
            NotificationManager.error(
              "Error while buying NFT, Something might have changed",
              "",
              NOTIFICATION_DELAY
            );
            evt.emit("txn-error", "user-denied-transaction");
            return false;
          }
          let req = {
            recordID: id,
            DBCollection: "Order",
            hashStatus: 0,
            hash: completeOrder.hash,
          };
          try {
            await UpdateStatus(req);
          } catch (e) {
            console.log("error 279", e);
            NotificationManager.error(
              "Error while buying NFT, Something might have changed",
              "",
              NOTIFICATION_DELAY
            );
            return false;
          }

          try {
            if (isERC721) {
              try {
                let res = await completeOrder.wait();

                if (res.status === 0) {
                  return false;
                }
              } catch (e) {
                console.log("err", e);
                evt.emit("txn-error", "transaction-failed");
                NotificationManager.error(
                  "Error while buying NFT, Something might have changed",
                  "",
                  NOTIFICATION_DELAY
                );
                return false;
              }

              evt.emit("txn-status", "transaction-succeeded");
              let req = {
                recordID: id,
                DBCollection: "Order",
                hashStatus: 1,
              };
              try {
                historyData.hash = completeOrder.hash;
                let updateRes = await UpdateStatus(req, historyData);
                if (updateRes === false) {
                  return false;
                }
              } catch (e) {
                console.log("error 314", e);
                NotificationManager.error(
                  "Error while buying NFT, Something might have changed",
                  "",
                  NOTIFICATION_DELAY
                );
                return false;
              }
              try {
                await UpdateOrder({
                  orderID: id,
                  nftID: details.nftID._id,
                  seller: details.sellerID.walletAddress?.toLowerCase(),
                  qtyBought: Number(qty),
                  qty_sold: Number(details.quantity_sold) + Number(qty),
                  buyer: account?.toLowerCase(),
                  LazyMintingStatus:
                    details.nftID.quantity_minted + qty ===
                    details.nftID.totalQuantity
                      ? 0
                      : 1,
                  quantity_minted:
                    details.nftID.quantity_minted ===
                    details.nftID.totalQuantity
                      ? details.nftID.quantity_minted
                      : details.nftID.quantity_minted + qty,
                  hashStatus: 1,
                  amount: buyerOrder[6]?.toString(),
                });
                await DeleteOrder({ orderID: id });
              } catch (e) {
                console.log("error in updating order data", e);
                NotificationManager.error(
                  "Error while buying NFT, Something might have changed",
                  "",
                  NOTIFICATION_DELAY
                );
                return false;
              }
            } else {
              let res = await completeOrder.wait();

              if (res.status === 0) {
                return false;
              }
              evt.emit("txn-status", "transaction-succeeded");
              let req = {
                recordID: id,
                DBCollection: "Order",
                hashStatus: 1,
              };
              try {
                historyData.hash = completeOrder.hash;
                let updateRes = await UpdateStatus(req, historyData);
                if (updateRes === false) {
                  return false;
                }
              } catch (e) {
                console.log("error 364", e);
                NotificationManager.error(
                  "Error while buying NFT, Something might have changed",
                  "",
                  NOTIFICATION_DELAY
                );
                return false;
              }
              try {
                await UpdateOrder({
                  orderID: id,
                  nftID: details.nftID._id,
                  seller: details.sellerID.walletAddress,
                  qtyBought: Number(qty),
                  qty_sold: Number(details.quantity_sold) + Number(qty),
                  buyer: account?.toLowerCase(),
                  LazyMintingStatus:
                    details.nftID.quantity_minted + qty ===
                    details.nftID.totalQuantity
                      ? 0
                      : 1,
                  quantity_minted:
                    details.nftID.quantity_minted ===
                    details.nftID.totalQuantity
                      ? details.nftID.quantity_minted
                      : details.nftID.quantity_minted + qty,

                  hashStatus: 0,
                  amount: buyerOrder[6]?.toString(),
                });

                if (
                  Number(details.quantity_sold) + Number(qty) >=
                  details.total_quantity
                ) {
                  try {
                    await DeleteOrder({ orderID: id });
                  } catch (e) {
                    evt.emit("txn-error", "Error");
                    NotificationManager.error(
                      "Error while buying NFT, Something might have changed",
                      "",
                      NOTIFICATION_DELAY
                    );
                    console.log("error in updating order data", e);
                    return false;
                  }
                }
              } catch (e) {
                console.log("error 403", e);
                evt.emit("txn-error", "Error");
                NotificationManager.error(
                  "Error while buying NFT, Something might have changed",
                  "",
                  NOTIFICATION_DELAY
                );
                console.log("error in updating order data", e);
                return false;
              }
            }
          } catch (e) {
            console.log("error 411", e);
            evt.emit("txn-error", "Error");
            NotificationManager.error(
              "Error while buying NFT, Something might have changed",
              "",
              NOTIFICATION_DELAY
            );
            console.log("error in updating order data", e);
            return false;
          }
        } else {
          evt.emit("txn-error", "transaction-failed");
          NotificationManager.error(
            "Error while buying NFT, Something might have changed",
            "",
            NOTIFICATION_DELAY
          );
          return false;
        }
      }
    } catch (e) {
      console.log("error 426", e);
      if (
        e.code === 4001 ||
        e.code === 5001 ||
        JSON.stringify(e).includes("user rejected transaction") ||
        e.toString().includes("Error: User canceled")
      ) {
        evt.emit("txn-error", "user-denied-transaction");
        return false;
      }
      if (JSON.stringify(e).includes(`"reason":"Not an owner"`)) {
        alert("Not an owner");
        console.log("error in contract function calling", e);
        return false;
      }
      if (e.toString().includes("Cancelled or complete")) {
        evt.emit("txn-error", "Error");
        NotificationManager.info(
          "Order either completed or cancelled",
          "",
          NOTIFICATION_DELAY
        );
        await DeleteOrder({ orderID: id });
        return false;
      }

      evt.emit("txn-error", "transaction-failed");
      NotificationManager.error(
        "Error while buying NFT, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );
      return false;
    }
  } catch (e) {
    console.log("error in contract function calling", e);
    if (
      e.code === 4001 ||
      e.code === 5001 ||
      JSON.stringify(e).includes("user rejected transaction") ||
      e.toString().includes("Error: User canceled")
    ) {
      evt.emit("txn-error", "user-denied-transaction");
      return false;
    }
    evt.emit("txn-error", "transaction-failed");
    NotificationManager.error(
      "Error while buying NFT, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }

  NotificationManager.success(
    "NFT Purchased Successfully",
    "",
    NOTIFICATION_DELAY
  );
  return true;
};

export const handleApproveToken = async (
  userAddress,
  tokenAddress,
  web3Provider
) => {
  try {
    let token = await exportInstance(tokenAddress, erc20Abi, web3Provider);

    let es = await token.estimateGas.approve(
      contracts.MARKETPLACE,
      MAX_ALLOWANCE_AMOUNT
    );

    let priceLimit = new BigNumber(es.toString()).plus(
      new BigNumber(es.toString()).multipliedBy(0.1)
    );

    const options = {
      from: userAddress,
      // gasPrice: 10000000000,
      gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
      value: 0,
    };
    let res = await token.approve(
      contracts.MARKETPLACE,
      MAX_ALLOWANCE_AMOUNT,
      options
    );
    res = await res.wait();

    if (res.status === 1) {
      evt.emit("txn-status", "token-approval-success");
      return res;
    }
  } catch (e) {
    console.log("error in contract function calling", e);
    if (
      e.code === 4001 ||
      JSON.stringify(e).includes("user rejected transaction") ||
      e.toString().includes("Error: User canceled")
    ) {
      evt.emit("txn-error", "user-denied-token-approval");
      return false;
    }
    evt.emit("txn-error", "transaction-failed");
    return false;
  }
};

export const putOnMarketplace = async (account, orderData, web3Provider) => {
  if (!account) {
    return false;
  }
  let _deadline = GENERAL_TIMESTAMP + moment().unix();
  let _price;
  let sellerOrder;

  console.log("order", orderData);
  try {
    if (orderData.chosenType === 3) {
      console.log("in this sale type");
      _deadline = GENERAL_TIMESTAMP + moment().unix();
      _price = ethers.utils.parseEther(orderData.price.toString()).toString();
    } else if (orderData.chosenType === 4) {
      let endTime;
      if (orderData.endTime === GENERAL_DATE) {
        endTime =
          new Date(orderData.endTime).valueOf() / 1000 + moment().unix();
      } else {
        endTime = new Date(orderData.endTime).valueOf() / 1000;
      }

      _deadline = endTime;

      _price = ethers.utils.parseEther(orderData.price.toString()).toString();
    } else if (orderData.chosenType === 4) {
      _deadline = GENERAL_TIMESTAMP + moment().unix();

      _price = ethers.utils.parseEther(orderData.price.toString()).toString();
    }

    sellerOrder = [
      account,
      orderData.collection,
      Number(orderData.tokenId),
      Number(orderData.quantity),
      orderData.saleType,
      orderData.tokenAddress
        ? orderData.tokenAddress?.toLowerCase()
        : "0x0000000000000000000000000000000000000000",
      _price,
      _deadline,
      [Number(orderData.tokenId)],
      [Number(orderData.quantity)],
      orderData.salt,
    ];


    console.log("***************SELLER ORDER******************", sellerOrder);

    if (!sellerOrder[0]) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }
    let usrHaveQuantity = await GetOwnerOfToken(
      sellerOrder[1],
      sellerOrder[2],
      orderData.erc721,
      sellerOrder[0]
    );

    if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      return false;
    }

    let NFTcontract = await exportInstance(
      orderData.collection,
      erc721Abi.abi,
      web3Provider
    );

    evt.emit("txn-status", "approval-initiated");
    let approval = await NFTcontract.isApprovedForAll(
      account,
      contracts.MARKETPLACE
    );
    let approvalres;
    if (approval) evt.emit("txn-status", "approval-succeeded");
    if (!approval) {
      evt.emit("txn-status", "approval-initiated");
      let es = await NFTcontract.estimateGas.setApprovalForAll(
        contracts.MARKETPLACE,
        true
      );
      let priceLimit = new BigNumber(es.toString()).plus(
        new BigNumber(es.toString()).multipliedBy(0.1)
      );
      const options = {
        from: account,
        gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
        value: 0,
      };
      approvalres = await NFTcontract.setApprovalForAll(
        contracts.MARKETPLACE,
        true,
        options
      );
      approvalres = await approvalres.wait();
      evt.emit("txn-status", "approval-succeeded");
      if (approvalres.status === 0) {
        evt.emit("txn-error", "transaction-failed");
        return false;
      }
    }
  } catch (e) {
    if (
      e.code === 4001 ||
      JSON.stringify(e).includes("user rejected transaction") ||
      e.toString().includes("Error: User canceled")
    ) {
      evt.emit("txn-error", "user-denied-approval");
      return false;
    }
    console.log("error in contract", e);
    evt.emit("txn-error", "transaction-failed");
    NotificationManager.error(
      "Error while placing order, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }
  try {
    let signature = [];

    evt.emit("txn-status", "sign-initiated");
    console.log("web3Provider in POM", web3Provider);
    signature = await getSignature(web3Provider, account, ...sellerOrder);
    console.log("**********************AFTER GET SIGNATURE************************", web3Provider, account, sellerOrder);
    if (signature === false) {
      evt.emit("txn-error", "user-denied-sign");
      return false;
    }
    evt.emit("txn-status", "sign-succeeded");
    let reqParams = {
      nftID: orderData.nftId,
      seller: account,
      tokenAddress: orderData?.tokenAddress
        ? orderData.tokenAddress
        : "0x0000000000000000000000000000000000000000",
      collectionAddress: orderData.collection,
      price: _price,
      quantity: Number(orderData.quantity),
      saleType: Number(orderData.saleType),
      deadline: Number(_deadline),
      signature: signature,
      tokenID: Number(orderData.tokenId),
      salt: Number(orderData.salt),
    };

    

    await createOrder(reqParams);
    NotificationManager.success(
      "Your Item Listed for Sale Successfully",
      "",
      NOTIFICATION_DELAY
    );
    return true;
  } catch (err) {
    evt.emit("txn-error", "transaction-failed");
    NotificationManager.error(
      "Error while placing order, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );
    console.log("error in Api", err);
    return false;
  }
};

export const handleRemoveFromSale = async (
  orderId,
  signature,
  historyData,
  web3Provider
) => {
  let order;
  order = await buildSellOrder(orderId);
  if (order === false) {
    evt.emit("txn-error", "Error");
    NotificationManager.info(
      "Owner has been changed or order deleted",
      "",
      NOTIFICATION_DELAY
    );
    slowRefresh(1000);
    return false;
  }
  if (!order[0]) {
    evt.emit("txn-error", "Error");
    NotificationManager.info(
      "Owner has been changed or order deleted",
      "",
      NOTIFICATION_DELAY
    );
    slowRefresh(1000);
    return false;
  }

  let usrHaveQuantity = await GetOwnerOfToken(order[1], order[2], 1, order[0]);

  if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
    NotificationManager.info(
      "Owner has been changed or order deleted",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }

  let { res, hash } = await handleCancelOrder(
    order,
    signature,
    orderId,
    true,
    web3Provider
  );
  if (res === false) {
    // NotificationManager.error("Error while removing NFT from sale, Something might have changed", "", NOTIFICATION_DELAY);
    return false;
  }

  try {
    const s = await DeleteOrder({
      orderID: orderId,
    });

    console.log("erro1", s);
    NotificationManager.success(
      "Removed from sale successfully",
      "",
      NOTIFICATION_DELAY
    );
  } catch (e) {
    console.log("error while updating database", e);
    NotificationManager.error(
      "Error while removing NFT from sale, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }
  try {
    historyData.hash = hash;
    await InsertHistory(historyData);
  } catch (e) {
    NotificationManager.error(
      "Error while removing NFT from sale, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );

    console.log("error", e);
  }
  return true;
};

export const createBid = async (
  nftID,
  orderID,
  ownerAccount,
  buyerAccount,
  erc721,
  qty = 1,
  bidPrice,
  isOffer = false,
  oldBidData = {},
  web3Provider
) => {
 
  let SellerOrder;
  let sellerOrder = [];
  let buyerOrder = [];
  try {
    const deadline = GENERAL_TIMESTAMP + moment().unix();
    SellerOrder = await buildSellOrder(orderID);
    if (SellerOrder === false) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }

    for (let index = 0; index < 11; index++) {
      switch (index) {
        case 0:
          sellerOrder.push(SellerOrder[index]);
          buyerOrder.push(buyerAccount);
          break;
        case 1:
          sellerOrder.push(SellerOrder[index]);
          buyerOrder.push(SellerOrder[index]);
          break;
        case 2:
          sellerOrder.push(Number(SellerOrder[index]));
          buyerOrder.push(Number(SellerOrder[index]));
          break;
        case 3:
          sellerOrder.push(SellerOrder[index]);
          buyerOrder.push(Number(qty));
          break;
        case 5:
          sellerOrder.push(SellerOrder[index]);
          buyerOrder.push(SellerOrder[index]);
          break;
        case 6:
          sellerOrder.push(SellerOrder[index]);
          buyerOrder.push(bidPrice?.toString());
          break;
        case 7:
          sellerOrder.push(SellerOrder[index]);
          buyerOrder.push(deadline);
          break;
        case 8:
          sellerOrder.push([parseInt(SellerOrder[2])]);
          buyerOrder.push([parseInt(SellerOrder[2])]);
          break;
        case 9:
          sellerOrder.push([parseInt(SellerOrder[3])]);
          buyerOrder.push([parseInt(SellerOrder[3])]);
          break;
        default:
          sellerOrder.push(parseInt(SellerOrder[index]));
          buyerOrder.push(parseInt(SellerOrder[index]));
      }
    }

    if (!isEmptyObject(oldBidData)) {
      let oldBuyerOrder = await buildBuyerOrder(oldBidData);

      let { res } = await handleCancelOrder(
        oldBuyerOrder,
        oldBidData?.buyerSignature,
        oldBidData._id,
        false,
        web3Provider
      );
      if (res === false) {
        evt.emit("txn-error", "Error");

        return false;
      }
      await deleteBids({ bidID: oldBuyerOrder._id });
    }

    if (
      !buyerOrder[0] ||
      buyerOrder[0].toLowerCase() === sellerOrder[0].toLowerCase()
    ) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }

    if (!sellerOrder[0]) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }
    let usrHaveQuantity = await GetOwnerOfToken(
      sellerOrder[1],
      sellerOrder[2],
      erc721,
      sellerOrder[0]
    );
    if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      return false;
    }

    try {
      evt.emit("txn-status", "token-approval-initiated");
      let allowance = (
        await getPaymentTokenInfo(buyerAccount, sellerOrder[5])
      ).allowance.toString();

      let userTokenBal = await getUsersTokenBalance(
        buyerOrder[0],
        buyerOrder[5]
      );

      if (
        new BigNumber(SellerOrder[6]).isGreaterThan(new BigNumber(userTokenBal))
      ) {
        evt.emit("txn-error", "Error");
        NotificationManager.error(
          "User don't have sufficient token balance",
          "",
          NOTIFICATION_DELAY
        );
        return false;
      }

      if (new BigNumber(allowance).isLessThan(new BigNumber(SellerOrder[6]))) {
        let approvalRes = await handleApproveToken(
          buyerOrder[0],
          buyerOrder[5],
          web3Provider
        );

        if (approvalRes === false) return false;
      }
      evt.emit("txn-status", "token-approval-success");
      evt.emit("txn-status", "sign-initiated");
      let ress = await buildSellOrder(orderID);
      if (ress !== false) {
        let signature = await getSignature(
          web3Provider,
          buyerAccount,
          ...buyerOrder
        );
        if (signature === false) {
          evt.emit("txn-error", "user-denied-sign");
          return false;
        }
        if (signature) {
          evt.emit("txn-status", "sign-succeeded");
          let reqParams = {
            owner: ownerAccount,
            bidStatus: "Bid",
            bidPrice: bidPrice.toString(),
            nftID: nftID,
            orderID: orderID,
            bidQuantity: Number(qty),
            buyerSignature: signature,
            isOffer: isOffer,
            bidDeadline: deadline,
          };

          SellerOrder = await buildSellOrder(orderID);

          if (SellerOrder === false) {
            evt.emit("txn-error", "Error");
            NotificationManager.info(
              "Owner has been changed or order deleted",
              "",
              NOTIFICATION_DELAY
            );
            return false;
          }
          await createBidNft(reqParams);
        }
      } else {
        NotificationManager.error(
          "NFT is removed from sale",
          "",
          NOTIFICATION_DELAY
        );
        evt.emit("txn-error", "transaction-failed");
        return false;
      }
    } catch (e) {
      evt.emit("txn-error", "transaction-failed");
      console.log("error in api", e);
      return false;
    }
  } catch (e) {
    evt.emit("txn-error", "transaction-failed");
    NotificationManager.error(
      "Error while creating bid, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );

    console.log("error in api", e);
    return false;
  }
  return true;
};

export const createOffer = async (
  tokenId,
  collectionAddress,
  ownerAccount,
  buyerAccount,
  erc721,
  qty,
  bidPrice,
  deadline,
  nftID,
  paymentToken,
  oldOffer = {},
  web3Provider
) => {
  console.log("inside create offer");
  let buyerOrder = [];
  deadline = deadline + new Date().getSeconds();
  try {
    buyerOrder.push(buyerAccount);
    buyerOrder.push(collectionAddress);
    buyerOrder.push(parseInt(tokenId));
    buyerOrder.push(parseInt(qty));
    buyerOrder.push(4);
    buyerOrder.push(paymentToken);
    buyerOrder.push(bidPrice.toString());
    buyerOrder.push(deadline);
    buyerOrder.push([parseInt(tokenId)]);
    buyerOrder.push([parseInt(qty)]);
    buyerOrder.push(Math.round(Math.random() * 10000000));

    console.log("seller order", buyerOrder);

    if (!isEmptyObject(oldOffer)) {
      let oldBuyerOrder = [];
      oldBuyerOrder.push(buyerAccount);
      oldBuyerOrder.push(oldOffer?.nftID[0]?.collectionAddress);
      oldBuyerOrder.push(parseInt(oldOffer?.nftID[0]?.tokenID));
      oldBuyerOrder.push(oldOffer?.bidQuantity);
      oldBuyerOrder.push(4);
      oldBuyerOrder.push(oldOffer?.paymentToken);
      oldBuyerOrder.push(oldOffer?.bidPrice?.$numberDecimal);
      oldBuyerOrder.push(oldOffer?.bidDeadline);
      oldBuyerOrder.push([parseInt(oldOffer?.nftID[0]?.tokenID)]);
      oldBuyerOrder.push([parseInt(oldOffer?.bidQuantity)]);
      oldBuyerOrder.push(oldOffer?.salt);
      console.log("2222");
      let { res } = await handleCancelOrder(
        oldBuyerOrder,
        oldOffer?.buyerSignature,
        oldOffer._id,
        false,
        web3Provider
      );
      if (res === false) {
        // NotificationManager.error("Error while creating offer, Something might have changed", "", NOTIFICATION_DELAY);

        return false;
      }

      await deleteBids({ bidID: oldOffer._id });
    }

    if (
      !buyerOrder[0] ||
      buyerOrder[0].toLowerCase() === ownerAccount.address.toLowerCase()
    ) {
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      slowRefresh(1000);
      return false;
    }
    console.log("3333");
    let usrHaveQuantity = await GetOwnerOfToken(
      buyerOrder[1],
      buyerOrder[2],
      1,
      ownerAccount.address
    );
    if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
      evt.emit("txn-error", "transaction-failed");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      return false;
    }
    console.log("buyerOrder[5]", buyerOrder[5], buyerAccount);
    let allowance = (
      await getPaymentTokenInfo(buyerAccount, buyerOrder[5])
    ).allowance.toString();

    let userTokenBal = await getUsersTokenBalance(buyerOrder[0], buyerOrder[5]);
    console.log("4444");
    if (
      new BigNumber(bidPrice)
        .multipliedBy(new BigNumber(qty.toString()))
        .isGreaterThan(new BigNumber(userTokenBal))
    ) {
      evt.emit("txn-error", "transaction-failed");
      NotificationManager.error(
        "User don't have sufficient token balance",
        "",
        NOTIFICATION_DELAY
      );
      return false;
    }
    console.log("allowance", allowance?.toString());
    if (
      new BigNumber(allowance).isLessThan(
        new BigNumber(bidPrice.toString())
          .multipliedBy(new BigNumber(qty.toString()))
          .toString()
      )
    ) {
      evt.emit("txn-status", "token-approval-initiated");
      let approvalRes = await handleApproveToken(
        buyerOrder[0],
        buyerOrder[5],
        web3Provider
      );
      if (approvalRes === false) return false;
      let newAllowance = (
        await getPaymentTokenInfo(buyerAccount, buyerOrder[5])
      ).allowance.toString();
      if (
        new BigNumber(newAllowance).isLessThan(
          new BigNumber(bidPrice.toString())
            .multipliedBy(new BigNumber(qty.toString()))
            .toString()
        )
      ) {
        evt.emit("txn-error", "transaction-failed");
        return false;
      }
    }
    console.log("55555");
    try {
      evt.emit("txn-status", "token-approval-success");
      evt.emit("txn-status", "sign-initiated");
      console.log("66666");
      let signature = await getSignature(
        web3Provider,
        buyerAccount,
        ...buyerOrder
      );
      console.log("signature", signature);
      if (signature === false) {
        console.log("777777");
        evt.emit("txn-error", "user-denied-sign");
        return false;
      }
      if (signature) {
        evt.emit("txn-status", "sign-succeeded");
        let reqParams = {
          owner: ownerAccount,
          bidStatus: "MakeOffer",
          bidPrice: bidPrice.toString(),
          nftID: nftID,
          bidDeadline: deadline,
          bidQuantity: Number(qty),
          buyerSignature: signature,
          tokenAddress: collectionAddress,
          salt: buyerOrder[10],
          paymentToken: paymentToken,
        };
        console.log("88888");
        try {
          let offer = await createOfferNFT(reqParams);
          if (!isEmptyObject(offer)) {
            return true;
            // slowRefresh(1000);
          } else {
            evt.emit("txn-error", "Error");
            NotificationManager.error(
              "Error while creating offer, Something might have changed",
              "",
              NOTIFICATION_DELAY
            );
            return false;
          }
        } catch (e) {
          NotificationManager.error(
            "Error while creating offer, Something might have changed",
            "",
            NOTIFICATION_DELAY
          );

          return false;
        }
      }
    } catch (e) {
      if (
        e.code === 4001 ||
        JSON.stringify(e).includes("user rejected transaction") ||
        e.toString().includes("Error: User canceled")
      ) {
        evt.emit("txn-error", "user-denied-sign");
        return;
      }
      evt.emit("txn-error", "Error");
      NotificationManager.error(
        "Error while creating offer, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );

      console.log("error in api 1", e);
      return false;
    }
  } catch (e) {
    if (
      e.code === 4001 ||
      JSON.stringify(e).includes("user rejected transaction") ||
      e.toString().includes("Error: User canceled")
    ) {
      evt.emit("txn-error", "user-denied-sign");
      return;
    }
    evt.emit("txn-error", "Error");
    NotificationManager.error(
      "Error while creating offer, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );

    console.log("error in api 2", e);
    return false;
  }
};

export const handleAcceptBids = async (
  bidData,
  isERC721,
  historyData,
  web3Provider
) => {
  let order;
  let details;
  let options;
  try {
    order = await buildSellOrder(bidData.orderID);
    if (order === false) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Owner has been changed or order deleted",
        "",
        NOTIFICATION_DELAY
      );
      await deleteBids({ bidID: bidData._id });
      slowRefresh(1000);
      return false;
    }
    details = await getOrderDetails({ orderID: bidData.orderID });
  } catch (e) {
    console.log("error in API", e);
    return false;
  }
  let buyerOrder = [];
  let sellerOrder = [];

  // let amount = new BigNumber(bidData?.bidPrice?.$numberDecimal.toString())
  //   .multipliedBy(new BigNumber(bidData.bidQuantity.toString()))
  //   .toString();

  // console.log("amount", amount)
  for (let key = 0; key < 11; key++) {
    switch (key) {
      case 0:
        sellerOrder.push(order[key]);
        buyerOrder.push(bidData?.bidderID?.walletAddress);
        break;

      case 1:
        sellerOrder.push(order[key]);
        buyerOrder.push(order[key]);
        break;
      case 3:
        if (isERC721) {
          sellerOrder.push(Number(order[key]));
          buyerOrder.push(Number(order[key]));
        } else {
          sellerOrder.push(Number(order[key]));
          buyerOrder.push(Number(bidData.bidQuantity));
        }
        break;
      case 5:
        sellerOrder.push(order[key]);
        buyerOrder.push(order[key]);
        break;
      case 6:
        buyerOrder.push(bidData?.bidPrice?.$numberDecimal.toString());
        sellerOrder.push(order[key]);
        break;
      case 7:
        buyerOrder.push(bidData?.bidDeadline);
        sellerOrder.push(order[key]);

        break;
      case 8:
        sellerOrder.push([Number(order[2])]);
        buyerOrder.push([Number(order[2])]);
        break;
      case 9:
        sellerOrder.push([Number(order[3])]);
        buyerOrder.push([Number(order[3])]);
        break;
      default:
        sellerOrder.push(parseInt(parseInt(order[key])));
        buyerOrder.push(parseInt(parseInt(order[key])));
    }
  }

  if (!sellerOrder[0]) {
    evt.emit("txn-error", "Error");
    NotificationManager.info(
      "Owner has been changed or order deleted",
      "",
      NOTIFICATION_DELAY
    );
    slowRefresh(1000);
    return false;
  }

  let usrHaveQuantity = await GetOwnerOfToken(
    sellerOrder[1],
    sellerOrder[2],
    isERC721,
    sellerOrder[0]
  );

  if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
    evt.emit("txn-error", "Error");
    NotificationManager.info("Owner has been changed", "", NOTIFICATION_DELAY);
    // slowRefresh(1000)
    return false;
  }

  let sellerSignature = details.signature;
  let buyerSignature = bidData.buyerSignature;

  evt.emit("txn-status", "approval-initiated");

  let NFTcontract = await exportInstance(
    sellerOrder[1],
    isERC721 ? erc721Abi.abi : erc1155Abi.abi,
    web3Provider
  );

  let approval = await NFTcontract.isApprovedForAll(
    sellerOrder[0],
    contracts.MARKETPLACE
  );

  if (!approval) {
    evt.emit("txn-error", "Error");
    NotificationManager.error(
      "Seller didn't approved marketplace",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  } else {
    evt.emit("txn-status", "approval-succeeded");
  }
  if (buyerOrder[5] === ZERO_ADDRESS) {
  } else {
    let paymentTokenData = await getPaymentTokenInfo(
      buyerOrder[0],
      buyerOrder[5]
    );

    if (
      new BigNumber(paymentTokenData.balance).isLessThan(
        new BigNumber(order[6].toString()).multipliedBy(
          new BigNumber(buyerOrder[3].toString())
        )
      )
    ) {
      evt.emit("txn-error", "Error");
      NotificationManager.error(
        "Buyer don't have enough Tokens",
        "",
        NOTIFICATION_DELAY
      );
      return false;
    }
  }
  try {
    let marketplace = await exportInstance(
      contracts.MARKETPLACE,
      marketPlaceABI.abi,
      web3Provider
    );
    let completeOrder;
    try {
      evt.emit("txn-status", "transaction-initiated");

      let es = await marketplace.estimateGas.completeOrder(
        sellerOrder,
        sellerSignature,
        buyerOrder,
        buyerSignature
      );

      let priceLimit = new BigNumber(es.toString()).plus(
        new BigNumber(es.toString()).multipliedBy(0.1)
      );

      options = {
        from: sellerOrder[0],
        // gasPrice: 10000000000,
        gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
        value: 0,
      };
      let checkCallStatic;

      try {
        checkCallStatic = await marketplace.callStatic.completeOrder(
          sellerOrder,
          sellerSignature,
          buyerOrder,
          buyerSignature,
          options
        );
      } catch (e) {
        console.log("err", e);
        evt.emit("txn-error", "transaction-failed");

        if (e.toString().includes("Cancelled or complete")) {
          evt.emit("txn-error", "Error");
          NotificationManager.info(
            "Order either completed or cancelled",
            "",
            NOTIFICATION_DELAY
          );

          await deleteBids({ bidID: bidData._id });

          return false;
        }
        NotificationManager.error(
          "Error while accepting bid, Something might have changed",
          "",
          NOTIFICATION_DELAY
        );
        await deleteBids({ bidID: bidData._id });
        return false;
      }

      if (checkCallStatic) {
        completeOrder = await marketplace.completeOrder(
          sellerOrder,
          sellerSignature,
          buyerOrder,
          buyerSignature,
          options
        );
        let req = {
          recordID: bidData.orderID[0]._id,
          DBCollection: "Order",
          hashStatus: 0,
          hash: completeOrder.hash,
        };

        try {
          await UpdateStatus(req);
        } catch (e) {
          return false;
        }
        let completeOrderRes;
        try {
          completeOrderRes = await completeOrder.wait();
        } catch (e) {
          console.log("err", e.code);
          NotificationManager.error(
            `Something went wrong: ${e.code}`,
            "",
            NOTIFICATION_DELAY
          );
          evt.emit("txn-error", "Error");
          return false;
        }
        if (completeOrderRes.status === 0) {
          evt.emit("txn-error", "transaction-failed");
          return false;
        }
        evt.emit("txn-status", "transaction-succeeded");
      }
    } catch (e) {
      console.log("error", e);
      if (
        e.code === 4001 ||
        e.code === 5001 ||
        JSON.stringify(e).includes("user rejected transaction") ||
        e.toString().includes("Error: User canceled")
      ) {
        evt.emit("txn-error", "user-denied-transaction");

        return false;
      }
      if (e.toString().includes("Cancelled or complete")) {
        evt.emit("txn-error", "transaction-failed");
        NotificationManager.info(
          "Order either completed or cancelled",
          "",
          NOTIFICATION_DELAY
        );

        await deleteBids({ bidID: bidData._id });
        return false;
      }
      NotificationManager.error(
        "Error while accepting bid, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );
      // await deleteBids({ bidID: bidData._id })
      return false;
    }
    let req = {
      recordID: bidData.orderID[0]?._id,
      DBCollection: "Order",
      hashStatus: 1,
      hash: completeOrder.transactionHash,
    };
    try {
      historyData.hash = completeOrder.transactionHash;
      let updateRes = await UpdateStatus(req, historyData);
      if (updateRes === false) {
        return false;
      }
    } catch (e) {
      NotificationManager.error(
        "Error while accepting bid, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );
      // await deleteBids({ bidID: bidData._id })
      return false;
    }
    try {
      await UpdateOrder({
        orderID: bidData.orderID[0]?._id,
        nftID: details?.nftID?._id,
        seller: details?.sellerID?.walletAddress,
        qtyBought: Number(bidData.bidQuantity),
        qty_sold: Number(details.quantity_sold) + Number(bidData.bidQuantity),
        buyer: buyerOrder[0]?.toLowerCase(),
        amount: buyerOrder[6],
      });

      if (
        Number(details.quantity_sold) + Number(bidData.bidQuantity) >=
        details.total_quantity
      ) {
        DeleteOrder({ orderID: bidData.orderID[0]?._id });
      }
    } catch (e) {
      console.log("error in updating order data", e);
      if (e.toString().includes("Cancelled or complete")) {
        NotificationManager.info(
          "Order is Either Completed or Cancelled",
          "",
          NOTIFICATION_DELAY
        );
        await deleteBids({ bidID: bidData._id });
      } else
        NotificationManager.error(
          "Error while accepting bid, Something might have changed",
          "",
          NOTIFICATION_DELAY
        );

      return false;
    }
  } catch (e) {
    console.log("error in contract function calling", e);
    if (
      e.code === 4001 ||
      e.code === 5001 ||
      JSON.stringify(e).includes("user rejected transaction") ||
      e.toString().includes("Error: User canceled")
    ) {
      evt.emit("txn-error", "user-denied-transaction");
      return false;
    }
    evt.emit("txn-error", "transaction-failed");
    if (e.toString().includes("Cancelled or complete")) {
      NotificationManager.info(
        "Order is Either Completed or Cancelled",
        "",
        NOTIFICATION_DELAY
      );
      await deleteBids({ bidID: bidData._id });
    } else
      NotificationManager.error(
        "Error while accepting bid, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );

    return false;
  }
  NotificationManager.success(
    "Bid Accepted Successfully",
    "",
    NOTIFICATION_DELAY
  );
  return true;
};

export const handleAcceptOffers = async (
  bidData,
  props,
  account,
  historyData,
  web3Provider
) => {
  let buyerOrder = [];
  let sellerOrder = [];
  // let amount = new BigNumber(bidData?.bidPrice?.$numberDecimal.toString())
  //   .multipliedBy(new BigNumber(bidData.bidQuantity.toString()))
  //   .toString();

  for (let key = 0; key < 11; key++) {
    switch (key) {
      case 0:
        sellerOrder.push(account);
        buyerOrder.push(bidData?.bidderID?.walletAddress);
        break;

      case 1:
        sellerOrder.push(props.NftDetails.collectionAddress);
        buyerOrder.push(props.NftDetails.collectionAddress);
        break;
      case 2:
        sellerOrder.push(Number(props.NftDetails.tokenId));
        buyerOrder.push(Number(props.NftDetails.tokenId));
        break;
      case 3:
        if (props.NftDetails.type === 1) {
          sellerOrder.push(Number(1));
          buyerOrder.push(Number(1));
        } else {
          sellerOrder.push(Number(bidData.bidQuantity));
          buyerOrder.push(Number(bidData.bidQuantity));
        }

        break;
      case 4:
        sellerOrder.push(4);
        buyerOrder.push(4);
        break;
      case 5:
        sellerOrder.push(bidData.paymentToken?.toLowerCase());
        buyerOrder.push(bidData.paymentToken?.toLowerCase());
        break;
      case 6:
        buyerOrder.push(bidData.bidPrice.$numberDecimal?.toString());
        sellerOrder.push(bidData.bidPrice.$numberDecimal?.toString());
        break;
      case 7:
        buyerOrder.push(bidData.bidDeadline);
        sellerOrder.push(bidData.bidDeadline);

        break;
      case 8:
        sellerOrder.push([Number(props.NftDetails.tokenId)]);
        buyerOrder.push([Number(props.NftDetails.tokenId)]);
        break;
      case 9:
        sellerOrder.push([Number(bidData.bidQuantity)]);
        buyerOrder.push([Number(bidData.bidQuantity)]);
        break;
      case 10:
        sellerOrder.push(Number(bidData.salt));
        buyerOrder.push(Number(bidData.salt));
        break;
      default:
        sellerOrder.push([]);
        buyerOrder.push([]);
    }
  }

  console.log("seller orderrr", sellerOrder, buyerOrder);
  if (!sellerOrder[0]) {
    evt.emit("txn-error", "Error");
    NotificationManager.info(
      "Owner has been changed or order deleted",
      "",
      NOTIFICATION_DELAY
    );
    slowRefresh(1000);
    return false;
  }

  let usrHaveQuantity = await GetOwnerOfToken(
    sellerOrder[1],
    sellerOrder[2],
    1,
    sellerOrder[0]
  );
  if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
    evt.emit("txn-error", "Error");
    NotificationManager.info(
      "Owner has been changed or order deleted",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }

  if (buyerOrder[0].toLowerCase() === sellerOrder[0].toLowerCase()) {
    evt.emit("txn-error", "Error");
    NotificationManager.error(
      "You can't accept your offer",
      "",
      NOTIFICATION_DELAY
    );
    await deleteBids({ bidID: bidData._id });
    return false;
  }

  let sellerSignature = bidData.buyerSignature;
  let buyerSignature = bidData.buyerSignature;

  console.log("seller sellerSignature", sellerSignature);
  evt.emit("txn-status", "approval-initiated");
  console.log("before calling exportInstance", sellerOrder[1], props.NftDetails.type);
  let NFTcontract = await exportInstance(
    sellerOrder[1],
    props.NftDetails.type ? erc721Abi.abi : erc1155Abi.abi,
    web3Provider
  );
  console.log("Nftcontract", NFTcontract, sellerOrder[0],
    contracts.MARKETPLACE);
  let approval = await NFTcontract.isApprovedForAll(
    sellerOrder[0],
    contracts.MARKETPLACE
  );

  console.log("approval", approval);
  let approvalRes;
  let apFee = await NFTcontract.estimateGas.setApprovalForAll(
    contracts.MARKETPLACE,
    true
  );

  let priceLimit = new BigNumber(apFee.toString()).plus(
    new BigNumber(apFee.toString()).multipliedBy(0.1)
  );

  let options = {
    from: account,
    // gasPrice: 10000000000,
    gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
    value: 0,
  };
  if (!approval) {
    try {
      approvalRes = await NFTcontract.setApprovalForAll(
        contracts.MARKETPLACE,
        true,
        options
      );

      let a = await approvalRes.wait();
      if (a.status !== 1) {
        evt.emit("txn-error", "Error");
        NotificationManager.error(
          "Marketplace has not approval",
          "",
          NOTIFICATION_DELAY
        );
        return false;
      }
    } catch (e) {
      console.log("error", e);
      if (
        e.code === 4001 ||
        JSON.stringify(e).includes("user rejected transaction") ||
        e.toString().includes("Error: User canceled")
      ) {
        evt.emit("txn-error", "user-denied-approval");
        return false;
      }
      evt.emit("txn-error", "Error");
      NotificationManager.error(
        "Error while accepting offer, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );
      await deleteBids({ bidID: bidData._id });
      return false;
    }

    evt.emit("txn-status", "approval-succeeded");
  } else {
    evt.emit("txn-status", "approval-succeeded");
  }

  let paymentTokenData = await getPaymentTokenInfo(
    buyerOrder[0],
    buyerOrder[5]
  );
  if (
    new BigNumber(paymentTokenData.balance).isLessThan(
      new BigNumber(buyerOrder[6].toString()).multipliedBy(
        new BigNumber(buyerOrder[3].toString())
      )
    )
  ) {
    evt.emit("txn-error", "Error");
    NotificationManager.error(
      "Buyer don't have enough Tokens",
      "",
      NOTIFICATION_DELAY
    );
    return false;
  }

  try {
    let marketplace = await exportInstance(
      contracts.MARKETPLACE,
      marketPlaceABI.abi,
     web3Provider
    );
    let completeOrder;
    try {
      evt.emit("txn-status", "transaction-initiated");

      let es = await marketplace.estimateGas.completeOrder(
        sellerOrder,
        sellerSignature,
        buyerOrder,
        buyerSignature
      );

      let priceLimit = new BigNumber(es.toString()).plus(
        new BigNumber(es.toString()).multipliedBy(0.1)
      );

      options = {
        from: sellerOrder[0],
        // gasPrice: 10000000000,
        gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
        value: 0,
      };

      let checkCallStatic = await marketplace.callStatic.completeOrder(
        sellerOrder,
        sellerSignature,
        buyerOrder,
        buyerSignature,
        options
      );
      if (checkCallStatic) {
        completeOrder = await marketplace.completeOrder(
          sellerOrder,
          sellerSignature,
          buyerOrder,
          buyerSignature,
          options
        );
        let req = {
          recordID: bidData._id,
          DBCollection: "Bids",
          hashStatus: 0,
          hash: completeOrder.hash,
        };
        try {
          await UpdateStatus(req);
        } catch (e) {
          console.log("error", e);
          evt.emit("txn-error", "Error");
          NotificationManager.error(
            "Error while accepting offer, Something might have changed",
            "",
            NOTIFICATION_DELAY
          );
          await deleteBids({ bidID: bidData._id });
          return false;
        }
        try {
          completeOrder = await completeOrder.wait();
        } catch (e) {
          console.log("err", e);
          evt.emit("txn-error", "transaction-failed");

          if (e.toString().includes("Cancelled or complete")) {
            NotificationManager.info(
              "Order is Either Completed or Cancelled",
              "",
              NOTIFICATION_DELAY
            );
            await deleteBids({ bidID: bidData._id });
          } else
            NotificationManager.error(
              "Error while accepting offer, Something might have changed",
              "",
              NOTIFICATION_DELAY
            );
          return false;
        }
        if (completeOrder.status === 0) {
          evt.emit("txn-error", "transaction-failed");
          NotificationManager.error(
            "Error while accepting offer, Something might have changed",
            "",
            NOTIFICATION_DELAY
          );

          return false;
        }
        evt.emit("txn-status", "transaction-succeeded");
      }
    } catch (e) {
      if (
        e.code === 4001 ||
        e.code === 5001 ||
        JSON.stringify(e).includes("user rejected transaction") ||
        e.toString().includes("Error: User canceled")
      ) {
        evt.emit("txn-error", "user-denied-transaction");

        return false;
      }

      if (e.toString().includes("Cancelled or complete")) {
        evt.emit("txn-error", "Error");
        NotificationManager.info(
          "Order either completed or cancelled",
          "",
          NOTIFICATION_DELAY
        );
        await deleteBids({ bidID: bidData._id });
        return false;
      }
      evt.emit("txn-error", "Error");
      console.log("error in contract", e?.reason);
      NotificationManager.error(e?.reason?.toString(), "", NOTIFICATION_DELAY);
      // NotificationManager.error("Error while accepting offer, Something might have changed", "", NOTIFICATION_DELAY);
      // await deleteBids({ bidID: bidData._id })
      return false;
    }

    try {
      let req = {
        recordID: bidData._id,
        DBCollection: "Bids",
        hashStatus: 1,
        hash: completeOrder.transactionHash,
      };
      try {
        historyData.hash = completeOrder.transactionHash;
        let updateRes = await UpdateStatus(req, historyData);
        if (updateRes === false) {
        }
      } catch (e) {
        evt.emit("txn-error", "Error");
        NotificationManager.error(
          "Error while accepting offer, Something might have changed",
          "",
          NOTIFICATION_DELAY
        );
      }

      let reqParams = {
        bidID: bidData._id,
        nftID: bidData?.nftID[0]?._id, //to make sure we update the quantity left : NFTid
        seller: account, //to make sure we update the quantity left : walletAddress
        qtyBought: 1,
        qty_sold: 1,
        buyer: bidData.bidderID._id,
        erc721: props.NftDetails.type === 1 ? 1 : 2,
        amount: bidData.bidPrice.$numberDecimal?.toString(),
      };

      await acceptOffer(reqParams);
    } catch (e) {
      evt.emit("txn-error", "Error");
      console.log("error in api", e);
      NotificationManager.error(
        "Error while accepting offer, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );

      return false;
    }
    try {
      await handleUpdateBidStatus(bidData._id, "Accepted", "", web3Provider);
    } catch (e) {
      evt.emit("txn-error", "Error");
      console.log("error in updating order data", e);
      NotificationManager.error(
        "Error while accepting offer, Something might have changed",
        "",
        NOTIFICATION_DELAY
      );

      return false;
    }
  } catch (e) {
    console.log("error in contract function calling", e);
    if (
      e.code === 4001 ||
      e.code === 5001 ||
      JSON.stringify(e).includes("user rejected transaction") ||
      e.toString().includes("Error: User canceled")
    ) {
      evt.emit("txn-error", "user-denied-transaction");
      return false;
    }
    evt.emit("txn-error", "transaction-failed");
    NotificationManager.error(
      "Error while accepting offer, Something might have changed",
      "",
      NOTIFICATION_DELAY
    );
    // await deleteBids({ bidID: bidData._id })
    return false;
  }
  NotificationManager.success(
    "Offer Accepted Successfully",
    "",
    NOTIFICATION_DELAY
  );
  return true;
};

export const handleUpdateBidStatus = async (
  bidID,
  action,
  bidData = "", //Delete, Cancelled, Rejected,
  web3Provider
) => {
  try {
    let reqParams = {
      bidID: bidID,
      action: action, //Delete, Cancelled, Rejected
    };
    let tokenAddress;
    let tokenID;
    let seller;
    let buyerOrder = [];
    // let amount = new BigNumber(bidData?.bidPrice?.$numberDecimal?.toString())
    //   .multipliedBy(new BigNumber(bidData?.bidQuantity?.toString()))
    //   ?.toString();
    if (bidData?.bidStatus === "Bid") {
      buyerOrder = await buildBuyerOrder(bidData);
      tokenAddress = bidData?.orderID[0]?.collectionAddress;
      tokenID = bidData?.orderID[0]?.tokenID;
      seller = bidData?.owner?.walletAddress;
    } else {
      tokenAddress = bidData?.tokenAddress;
      tokenID = bidData?.nftID[0]?.tokenID;
      seller = bidData?.owner?.walletAddress;
      for (let key = 0; key < 11; key++) {
        switch (key) {
          case 0:
            buyerOrder.push(bidData?.bidderID?.walletAddress);
            break;

          case 1:
            buyerOrder.push(tokenAddress);
            break;
          case 2:
            buyerOrder.push(Number(tokenID));
            break;
          case 3:
            buyerOrder.push(Number(1));
            break;
          case 4:
            buyerOrder.push(1);
            break;
          case 5:
            buyerOrder.push(bidData.paymentToken?.toLowerCase());
            break;
          case 6:
            buyerOrder.push(bidData.bidPrice.$numberDecimal?.toString());
            break;
          case 7:
            buyerOrder.push(bidData.bidDeadline);

            break;
          case 8:
            buyerOrder.push([0]);
            break;
          case 9:
            buyerOrder.push([0]);
            break;
          case 10:
            buyerOrder.push(Number(bidData.salt));
            break;
          default:
            buyerOrder.push([1]);
        }
      }
    }
    if (bidData) {
      if (!bidData?.owner?.walletAddress) {
        evt.emit("txn-error", "Error");
        NotificationManager.info(
          "Owner has been changed or order deleted",
          "",
          NOTIFICATION_DELAY
        );

        await updateBidNft(reqParams);
        slowRefresh(1000);
        return { res: false, hash: "" };
      }

      let usrHaveQuantity = await GetOwnerOfToken(
        tokenAddress,
        tokenID,
        1,
        seller
      );

      if (usrHaveQuantity === 0 || usrHaveQuantity === false) {
        evt.emit("txn-error", "Error");
        NotificationManager.info(
          "Owner has been changed or order deleted",
          "",
          NOTIFICATION_DELAY
        );
        await updateBidNft(reqParams);
        return { res: false, hash: "" };
      }
    }
    let hash_;
    if (action === "Cancelled") {
      try {
        let { res, hash } = await handleCancelOrder(
          buyerOrder,
          bidData?.buyerSignature,
          bidID,
          false,
          web3Provider
        );
        hash_ = hash;
        if (res === false) return { res: false, hash: "" };
      } catch (e) {
        console.log("error in update status", e);
        return { res: false, hash: "" };
      }
    }
    await updateBidNft(reqParams);

    return { res: true, hash: hash_ };
  } catch (e) {
    console.log("error in api", e);
    return { res: false, hash: "" };
  }
};

export const handleCancelOrder = async (
  order,
  signature,
  bidId,
  isOrder,
  web3Provider
) => {
  evt.emit("txn-status", "cancel-initiated");
  let marketplace = await exportInstance(
    contracts.MARKETPLACE,
    marketPlaceABI.abi,
    web3Provider
  );
  let cancelOrder;
  try {
    let es = await marketplace.estimateGas.cancelOrder(order);

    let priceLimit = new BigNumber(es.toString()).plus(
      new BigNumber(es.toString()).multipliedBy(0.1)
    );

    const options = {
      from: order[0],
      gasLimit: Math.ceil(parseFloat(priceLimit.toString())),
      value: 0,
    };

    let checkCallStatic = await marketplace.callStatic.cancelOrder(
      order,
      options
    );
    if (checkCallStatic) {
      try {
        cancelOrder = await marketplace.cancelOrder(order, options);
      } catch (e) {
        console.log("error", e);
        if (
          e.code === 4001 ||
          JSON.stringify(e).includes("user rejected transaction") ||
          e.toString().includes("Error: User canceled")
        ) {
          evt.emit("txn-error", "user-denied-cancel");
          return { res: false, hash: "" };
        }
        NotificationManager.error(
          "Something might have changed during updation",
          "",
          NOTIFICATION_DELAY
        );

        evt.emit("txn-error", "Error");
        if (isOrder) {
          await DeleteOrder({ orderID: bidId });
        } else await deleteBids({ bidID: bidId });
        return { res: false, hash: "" };
      }
      try {
        let result = await cancelOrder.wait();
        if (result.status === 0) {
          evt.emit("txn-error", "Error");
          NotificationManager.error(
            "Something might have changed during updation",
            "",
            NOTIFICATION_DELAY
          );
          if (isOrder) {
            await DeleteOrder({ orderID: bidId });
          } else await deleteBids({ bidID: bidId });
          return { res: false, hash: "" };
        } else {
          evt.emit("txn-status", "cancel-succeeded");
          return { res: true, hash: result.transactionHash };
        }
      } catch (e) {
        evt.emit("txn-error", "transaction-failed");
        console.log("eee", e);
        NotificationManager.error(
          "Something might have changed during updation",
          "",
          NOTIFICATION_DELAY
        );
        if (e.code === "CALL_EXCEPTION") {
          if (e.toString().includes("Cancelled or complete")) {
            if (isOrder) {
              await DeleteOrder({ orderID: bidId });
            } else await deleteBids({ bidID: bidId });
            NotificationManager.error("Transaction is Cancelled or Completed");
            return { res: false, hash: "" };
          }
        } else {
          NotificationManager.error("Transaction Failed");
          return { res: false, hash: "" };
        }

        // NotificationManager.error("tx failed due to" + e.code)
        return { res: false, hash: "" };
      }
    }
  } catch (e) {
    console.log("error in cancel order", e);
    evt.emit("txn-error", "transaction-failed");
    if (e.toString().includes("Cancelled or complete")) {
      evt.emit("txn-error", "Error");
      NotificationManager.info(
        "Order is Either Completed or Cancelled",
        "",
        NOTIFICATION_DELAY
      );
      if (isOrder) {
        await DeleteOrder({ orderID: bidId });
      } else await deleteBids({ bidID: bidId });
      return { res: false, hash: "" };
    }
    return { res: false, hash: "" };
  }
};

export const getSalePercents = async (contractAddress) => {
  let contractInstance = await FetchInstance(
    contracts?.MARKETPLACE,
    marketPlaceABI.abi
  );
  let marketplaceFee = 0;
  let royaltyInfo;

  try {
    try {
      marketplaceFee = await contractInstance.marketplaceFee();
      console.log("marketplace fee", marketplaceFee?.toString());
    } catch (err) {
      console.log("error", err);
      return {
        marketplaceFee: marketplaceFee,
        royalty: royaltyInfo,
      };
    }
    try {
      let collection = await FetchInstance(contractAddress, erc721Abi.abi);

      royaltyInfo = await collection.royaltyInfo(
        1,
        ethers.utils.parseEther("100")
      );
      console.log("royaltyInfo fee", royaltyInfo?.royaltyAmount?.toString());

      return {
        marketplaceFee: marketplaceFee,
        royalty: royaltyInfo?.royaltyAmount?.toString(),
      };
    } catch (err) {
      console.log("error", err);
      return {
        marketplaceFee: marketplaceFee,
        royalty: 0,
      };
    }
  } catch (err) {
    console.log("error", err);
    return {
      marketplaceFee: marketplaceFee,
      royalty: 0,
    };
  }
};

export const switchNetwork = async () => {
  try {
    let provider = await getCurrentProvider();
    provider.request({
      method: "wallet_addEthereumChain",
      params: [
        {
          chainId: process.env.REACT_APP_CHAIN_ID,
          rpcUrls: [process.env.REACT_APP_RPC_URL],
          chainName: "Polygon Amoy",
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://amoy.polygonscan.com/"],
        },
      ],
    });
  } catch (err) {
    console.log("err", err);
  }
};
